import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

import { formatCurrency } from "../../../utils/math";

function RequireMinimumModal({ show, handleClose, balance }) {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <Modal.Body>
          <p className="text-center">{t("insufficientBalance")}</p>
          <p className="text-center">{`${t("minimumBalance")}: ${formatCurrency(
            balance ?? 0
          )} THB`}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="me-2 btn-block btn-md"
            variant="danger"
            onClick={handleClose}
          >
            {t("cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RequireMinimumModal;
