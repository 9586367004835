/* eslint-disable react/prop-types */
import { Form, Button, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

function DepositModal({
  show,
  handleClose,
  handleConfirm,
  handleChange,
  loading,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <Modal.Body>
          <h5 className="text-center">{t("enterAmount")}</h5>
          <Form.Control
            type="number"
            placeholder="USDT"
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            className="me-2 btn-block btn-md"
            variant="danger"
            onClick={handleClose}
          >
            {t("cancel")}
          </Button>
          <Button
            className="me-2 btn-block btn-md"
            variant="primary"
            onClick={handleConfirm}
          >
            {loading ? <Spinner /> : t("buy")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DepositModal;
