export const randomOnlineUSer = () => {
  const min = 5000;
  const max = 10000;
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const formatCurrency = (number) => {
  const parseNumber = parseFloat(number).toFixed(2);
  const formated = parseNumber
    ?.toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return formated;
};
export const numberWithCommas = (x) => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const randomNumber = ({ min, max }) =>
  Math.floor(Math.random() * (max - min + 1)) + min;
