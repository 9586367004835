import { createSlice } from "@reduxjs/toolkit";

import { ROUTE } from "../../../constants/app_routes";
import {
  decodeToken,
  removeTokenFromLocalStorage,
} from "../../../services/AuthService";
import {
  STORAGE_KEY,
  getToken,
  removeToken,
} from "../../../services/LocalStorage";

const initialState = {
  isLogin: false,
};

const accessToken = getToken(STORAGE_KEY.ACCESS);
const refreshToken = getToken(STORAGE_KEY.REFRESH);

if (accessToken) {
  const decodedToken = decodeToken(accessToken);
  if (!decodedToken) {
    const decodedRefresh = decodeToken(refreshToken);
    if (!decodedRefresh) {
      removeToken(STORAGE_KEY.ACCESS);
      removeToken(STORAGE_KEY.REFRESH);
    }
  } else {
    initialState.isLogin = true;
  }
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    handleLoginSuccess: (state) => {
      if (!state.isLogin) {
        state.isLogin = true;
      }
    },
    logout: (state, { payload }) => {
      const { navigate } = payload;

      removeTokenFromLocalStorage();
      state.isLogin = false;
      navigate(ROUTE.LOGIN);
    },
  },
});

export const { handleLoginSuccess, logout } = authSlice.actions;

export default authSlice.reducer;
