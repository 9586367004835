import { useState } from "react";
import { useTranslation } from "react-i18next";

import BaseLayout from "../../components/layout";
import Header from "../../components/layout/headers/Header";
import CustomOffCanvas from "../../components/bootstrap/CustomOffCanvas";

import { ROUTE } from "../../constants/app_routes";
import {
  LanguageIcon,
  LockIcon,
  SettingIcon,
  UserCogIcon,
} from "../../constants/icon_path";
import SettingMenuItem from "./components/SettingMenuItem";
import LanguageItem from "./components/LanguageItem";
import { engFlag, thaiFlag } from "../../constants/image_path";

const Settings = () => {
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const onChangeLanguage = (ln) => {
    localStorage.setItem("language", ln);
    i18n.changeLanguage(ln);
  };

  return (
    <BaseLayout>
      <CustomOffCanvas
        show={show}
        handleClose={handleClose}
        title={t("switchLanguage")}
        placement="bottom"
        height="40%"
        backgroundColor="#f2f3f5"
      >
        <LanguageItem
          title="English"
          flag={engFlag}
          onClick={() => onChangeLanguage("en")}
          isSelected={i18n.language === "en"}
        />
        <LanguageItem
          title="ไทย"
          flag={thaiFlag}
          onClick={() => onChangeLanguage("th")}
          isSelected={i18n.language === "th"}
        />
      </CustomOffCanvas>

      <Header title={t("settings")} />
      <div className="settings">
        <SettingMenuItem
          to={ROUTE.UPDATE_PASSWORD}
          title={t("changePassword")}
          MenuIcon={LockIcon}
        />
        <SettingMenuItem
          to={ROUTE.SECURITY}
          title={t("setSecurityPassword")}
          MenuIcon={SettingIcon}
        />
        <SettingMenuItem
          to={ROUTE.UPDATE_PROFILE}
          title={t("updateProfile")}
          MenuIcon={UserCogIcon}
        />
        <SettingMenuItem
          title={t("switchLanguage")}
          MenuIcon={LanguageIcon}
          onClick={handleShow}
        />
      </div>
    </BaseLayout>
  );
};

export default Settings;
