import { useQuery } from "@apollo/client";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { WITHDRAW_RECORD } from "../../graphql/queries/profile";
import BaseLayout from "../../components/layout";
import TransactionTable from "./components/TransactionTable";
import Header from "../../components/layout/headers/Header";

const WithdrawRecord = () => {
  const { t } = useTranslation();
  const { data } = useQuery(WITHDRAW_RECORD);

  return (
    <BaseLayout>
      <Header title={t("withdrawRecord")} />
      <div className="profile">
        <Card>
          <TransactionTable data={data?.withdrawRecord} />
        </Card>
      </div>
    </BaseLayout>
  );
};

export default WithdrawRecord;
