import { useState } from "react";
import { useMutation } from "@apollo/client";
import { FormGroup, Label, Col, Input, Form } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { TOPUP_REQUEST } from "../../graphql/mutations/transaction";
import SubmitButton from "../../components/common/SubmitButton";
import { ROUTE } from "../../constants/app_routes";
import BaseLayout from "../../components/layout";
import {
  showErrorToast,
  showSuccessToast,
} from "../../components/common/CustomErrorMessage";
import { currentBranch } from "../../constants/app_constants";

const BankCardTopup = () => {
  const [amount, setAmount] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = location;

  const [topupRequest, { loading }] = useMutation(TOPUP_REQUEST, {
    variables: { amount, branch: currentBranch },
    onCompleted: (data) => {
      if (data.topupRequest) {
        navigate(ROUTE.TOPUP_DETAIL, { state: data.topupRequest });
        showSuccessToast("topupSuccessTitle");
      }
    },
    onError: (error) => {
      showErrorToast(error.message);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (amount <= 0) {
      toast.error(t("inputRechargeAmount"), {
        position: "top-center",
        autoClose: 5000,
      });
      return;
    }
    await topupRequest();
  };

  return (
    <BaseLayout>
      <div className="topup">
        {state.isBank ? (
          <>
            <div className="detail-header">
              <img className="img-thumbnail" src={state?.logo} alt="logo" />
              <h4 className="margin-auto-v">{state?.name}</h4>
            </div>
            <p className="text-center text-danger">{t("topupBankNotice")}</p>
            <Container>
              <div className="form-container">
                <Form onSubmit={handleSubmit}>
                  <FormGroup row>
                    <Label sm={3}>{t("rechargeAmount")}:</Label>
                    <Col sm={9}>
                      <Input
                        name="amount"
                        value={amount}
                        placeholder={t("inputRechargeAmount")}
                        type="number"
                        onChange={(e) => setAmount(parseInt(e.target.value))}
                      />
                    </Col>
                  </FormGroup>
                  <SubmitButton text={t("confirm")} loading={loading} />
                </Form>
              </div>
            </Container>
          </>
        ) : (
          <>
            <div className="detail-header d-flex">
              <img className="logo-type" src={state?.logo} alt="logo" />
              <h6>{state?.name}</h6>
            </div>
            <p className="text-center text-success">{t("topupNotice")}</p>
            <Container>
              <Form>
                <FormGroup row>
                  <Label for="exampleEmail" sm={3}>
                    {t("rechargeAmount")}
                  </Label>
                  <Col sm={9}>
                    <Input
                      name="email"
                      placeholder={t("inputRechargeAmount")}
                      type="text"
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="exampleEmail" sm={3}>
                    {t("remark")}
                  </Label>
                  <Col sm={9}>
                    <Input
                      name="email"
                      placeholder={t("pleaseEnterANote")}
                      type="text"
                    />
                  </Col>
                </FormGroup>
                <p className="text-danger">{t("warmRemindertitle")}</p>
                <p>{t("warmRemindertext")}</p>
                <div className="d-grid gap-2">
                  <Button variant="success" size="md">
                    {t("confirm")}
                  </Button>
                </div>
              </Form>
            </Container>
          </>
        )}
      </div>
    </BaseLayout>
  );
};

export default BankCardTopup;
