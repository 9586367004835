import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

import BaseLayout from "../../components/layout";
import Header from "../../components/layout/headers/Header";
import { security } from "../../constants/image_path";
import { useRef, useState } from "react";
import CustomOffCanvas from "../../components/bootstrap/CustomOffCanvas";
import CustomNumberPad from "../../components/common/CustomNumberPad";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { UPDATE_SECURITY_PASSWORD } from "../../graphql/mutations/profile";
import {
  showErrorToast,
  showSuccessToast,
} from "../../components/common/CustomErrorMessage";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../constants/app_routes";

const SecurityPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const divRef = useRef(null);
  const [show, setShow] = useState(false);
  const { securityPassword } = useSelector((state) => state.profile);
  const password = securityPassword.join("");

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleDivClick = () => {
    handleShow();
    divRef.current.focus();
  };

  const [updateSecurityPassword] = useMutation(UPDATE_SECURITY_PASSWORD, {
    variables: { password },
    onCompleted: () => {
      handleClose();
      showSuccessToast("");
      navigate(ROUTE.PROFILE);
    },
    onError: (error) => {
      showErrorToast(error.message);
    },
  });

  const handleSubmit = () => updateSecurityPassword();

  return (
    <BaseLayout>
      <CustomOffCanvas
        show={show}
        handleClose={handleClose}
        title="วิธีการเล่น"
        placement="bottom"
        showHeader={false}
        height="40%"
        backgroundColor="#f2f3f5"
        backdrop={false}
      >
        <CustomNumberPad handleClose={handleClose} />
      </CustomOffCanvas>
      <Header title={t("setSecurityPassword")} />
      <div className="security">
        <img className="security-img mt-4" src={security} />
        <h1 className="text-center">{t("setSecurityPassword")}</h1>
        <p className="text-center mt-2">{t("securityPasswordContent")}</p>
        <div className="security-input mt-5">
          <div
            className="security-input__box"
            contentEditable="true"
            onClick={handleDivClick}
          >
            <div className="security-input__box_text">
              {securityPassword.length >= 1 && "*"}
            </div>
          </div>
          <div
            className="security-input__box"
            contentEditable="true"
            onClick={handleDivClick}
          >
            <div className="security-input__box_text">
              {securityPassword.length >= 2 && "*"}
            </div>
          </div>
          <div
            className="security-input__box"
            contentEditable="true"
            onClick={handleDivClick}
          >
            <div className="security-input__box_text">
              {securityPassword.length >= 3 && "*"}
            </div>
          </div>
          <div
            className="security-input__box"
            contentEditable="true"
            onClick={handleDivClick}
          >
            <div className="security-input__box_text">
              {securityPassword.length >= 4 && "*"}
            </div>
          </div>
          <div
            className="security-input__box"
            contentEditable="true"
            onClick={handleDivClick}
          >
            <div className="security-input__box_text">
              {securityPassword.length >= 5 && "*"}
            </div>
          </div>
          <div
            className="security-input__box"
            contentEditable="true"
            onClick={handleDivClick}
          >
            <div className="security-input__box_text">
              {securityPassword.length >= 6 && "*"}
            </div>
          </div>
        </div>
        <div className="security-button d-grid gap-2 mt-4">
          <Button
            variant="success"
            size="lg"
            disabled={securityPassword.length < 6}
            onClick={handleSubmit}
          >
            {t("continue")}
          </Button>
        </div>
      </div>
    </BaseLayout>
  );
};

export default SecurityPassword;
