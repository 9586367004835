import { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ROUTE } from "../../constants/app_routes";
import Preloader from "../common/Preloader";
import { checkAutoLogin } from "../../services/AuthService";

const BaseLayout = ({ children }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    checkAutoLogin(dispatch, navigate);
  }, [dispatch, navigate, auth.isLogin]);

  useEffect(() => {
    if (!auth.isLogin) {
      setTimeout(() => navigate(ROUTE.LOGIN), 300);
    }
  }, [auth.isLogin, navigate]);

  return <Suspense fallback={<Preloader />}>{children}</Suspense>;
};

export default BaseLayout;
