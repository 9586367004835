import React from "react";
import ReactDOM from "react-dom/client";
import { ApolloProvider } from "@apollo/client";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";

import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
// import "./index.css";
import App from "./App";
import { client } from "./ApolloProvider";
import initialize from "./i18n";
import store from "./store";

// init i18n
initialize();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <Browserouter> */}
    <ApolloProvider client={client}>
      <Provider store={store}>
        <ToastContainer />
        <App />
      </Provider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
