import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";

import RowFormGroup from "../../../components/forms/RowFormGroup";
import {
  clearIdentityImage,
  handleChangeIdentityForm,
  setIdentityImageFile,
  setIdentityImagePreview,
  toggleIdentityModel,
} from "../redux/profileSlice";
import SubmitButton from "../../../components/common/SubmitButton";
import { VERIFY_IDENTITY } from "../../../graphql/mutations/profile";
import CustomImagePicker from "../../../components/common/CustomImagePicker";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../components/common/CustomErrorMessage";

const IdentityForm = ({ refetch }) => {
  const { identityForm } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const toggleIdentity = () => dispatch(toggleIdentityModel());

  const [verifyIdentity, { loading }] = useMutation(VERIFY_IDENTITY, {
    variables: {
      input: {
        cardName: identityForm.cardName,
        cardNo: identityForm.cardNo,
        imageFile: identityForm.file,
      },
    },
    onCompleted: (data) => {
      if (data.verifyIdentity) {
        toggleIdentity();
        showSuccessToast();
        refetch();
      }
    },
    onError(err) {
      showErrorToast(err.message);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    verifyIdentity();
  };

  const handleChange = (e) =>
    dispatch(
      handleChangeIdentityForm({
        ...identityForm,
        [e.target.name]: e.target.value,
      })
    );

  const handleSetFile = (file) => {
    dispatch(setIdentityImageFile(file));
  };
  const handleSetImagePreview = (preview) => {
    dispatch(setIdentityImagePreview(preview));
  };
  const handleRemoveImage = () => {
    dispatch(clearIdentityImage());
  };

  return (
    <Form onSubmit={handleSubmit}>
      <RowFormGroup
        label={t("cardName")}
        name="cardName"
        type="text"
        placeholder={t("requiredCardName")}
        onChange={handleChange}
      />
      <RowFormGroup
        label={t("cardNo")}
        name="cardNo"
        placeholder={t("requiredCardNo")}
        type="text"
        onChange={handleChange}
      />
      <CustomImagePicker
        label={t("cardImage")}
        setImageFile={handleSetFile}
        setImagePreview={handleSetImagePreview}
        uploadPreview={identityForm.uploadPreview}
        handleRemoveImage={handleRemoveImage}
      />
      <SubmitButton text={t("confirm")} loading={loading} />
    </Form>
  );
};

export default IdentityForm;
