import { useQuery } from "@apollo/client";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Col,
  Row,
} from "reactstrap";

import { GET_LATEST_GAME } from "../../../graphql/queries/game";
import LotteryResultItem from "./LotteryResultItem";

const PeriodAccordion = () => {
  const { t } = useTranslation();
  const { data: { getLatestGame } = {} } = useQuery(GET_LATEST_GAME);
  const [open, setOpen] = useState("");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <Accordion open={open} toggle={toggle}>
      <AccordionItem>
        <AccordionHeader targetId="1">
          {getLatestGame && <LotteryResultItem data={getLatestGame[0]} />}
        </AccordionHeader>
        <AccordionBody accordionId="1">
          <Row>
            <Col ms="4">
              <p className="ml-3">{t("issueNumber")}</p>
            </Col>
            <Col ms="8">
              <p className="ml-1">{t("lotteryResult")}</p>
            </Col>
            <Row className="mb-5 pb-5">
              <Col md="12">
                {getLatestGame?.map((game) => {
                  return (
                    <div key={game.id}>
                      <LotteryResultItem data={game} />
                    </div>
                  );
                })}
              </Col>
            </Row>
          </Row>
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  );
};

export default PeriodAccordion;
