import { useEffect, useRef } from "react";

import BetSkeleton from "./sketleton/BetSkeleton";
import { Badge, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const BetContainer = ({ data, loadingBets }) => {
  const { t, i18n } = useTranslation();
  const lng = i18n.language;
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [data]);

  const renderBetOptions = (options) => {
    if (options.length > 0) {
      return options?.map((option) => (
        <span key={option.id}>
          {lng === "th" ? option.name : option.engName}
        </span>
      ));
    } else {
      return <span>{lng === "th" ? "ทำมากขึ้น" : "Do more"}</span>;
    }
  };

  const renderMemberId = (id) => {
    const sub = id.substring(3);
    const hideId = `**** ${sub}`;
    return hideId;
  };

  return (
    <div className="game__bet-container">
      {loadingBets ? (
        <div>
          <BetSkeleton />
          <BetSkeleton />
          <BetSkeleton />
          <BetSkeleton />
          <BetSkeleton />
          <BetSkeleton />
        </div>
      ) : (
        <Table striped hover variant="dark">
          <thead>
            <tr>
              <th className="d-flex justify-content-between">
                <span></span>
                <span className="marquee">{t("user")}</span>
                <span></span>
                <span className="marquee">{t("rounds")}</span>
                <span className="marquee">{t("tradeCategory")}</span>
                <span className="marquee">{t("singleRound")}</span>
                <span className="marquee">{t("operation")}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <marquee direction="up" height="300" scrollamount="5">
              {data?.map((bet) => {
                return (
                  <tr key={bet.id} className="d-flex justify-content-between">
                    <td className="text-center">
                      {renderMemberId(bet.member.memberId)}
                    </td>
                    <td className="text-center">{bet.lottery.lotteryNo}</td>
                    <td className="text-center">
                      {renderBetOptions(bet.options)}
                    </td>
                    <td></td>
                    <td className="text-center">{bet.amount}</td>
                    <td></td>
                    <td className="text-center">
                      <Badge bg="info">{t("buy")}</Badge>
                    </td>
                  </tr>
                );
              })}
            </marquee>
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default BetContainer;
