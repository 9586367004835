import { Link } from "react-router-dom";

const HomeMenuItem = ({ icon, title, to }) => {
  return (
    <div className="home__menu-item">
      <Link to={to}>
        <div className="home__menu-item_icon">{icon}</div>
        <p>{title}</p>
      </Link>
    </div>
  );
};

export default HomeMenuItem;
