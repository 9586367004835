import CountryItem from "./CountryItem";

const CountrySkeleton = () => {
  return (
    <>
      <CountryItem />
      <CountryItem />
      <CountryItem />
      <CountryItem />
    </>
  );
};

export default CountrySkeleton;
