import { gql } from "@apollo/client";
import { TRANSACTION_INFO } from "../fragments";

export const TOPUP_REQUEST = gql`
  mutation topupRequest($amount: Int!, $branch: ID) {
    topupRequest(amount: $amount, branch: $branch) {
      ...transactionInfo
    }
  }
  ${TRANSACTION_INFO}
`;

export const WITHDRAW_REQUEST = gql`
  mutation withdrawRequest($amount: Int!, $branch: ID, $password: String!) {
    withdrawRequest(amount: $amount, branch: $branch, password: $password) {
      ...transactionInfo
    }
  }
  ${TRANSACTION_INFO}
`;

export const DEPOSIT = gql`
  mutation deposit($input: DepositInput!) {
    deposit(input: $input) {
      id
      profit
      amount
    }
  }
`;
