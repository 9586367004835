import thaiFlag from "../assets/images/flag/thai.jpg";
import engFlag from "../assets/images/flag/eng.png";
import image1 from "../assets/images/room-1.png";
import image2 from "../assets/images/room-2.png";
import image3 from "../assets/images/room-3.png";
import image4 from "../assets/images/room-1.png";
import image5 from "../assets/images/room-2.png";
import image6 from "../assets/images/room-3.png";
import newHomeBg from "../assets/images/new-home-bg.jpg";
import about from "../assets/images/about.png";
import homeBg from "../assets/images/home-bg.jpeg";
import homeBg2 from "../assets/images/home-bg2.jpg";
import homeBg3 from "../assets/images/home-bg3.jpg";

import trending1 from "../assets/images/trending1.png";
import trending2 from "../assets/images/trending2.png";
import trending3 from "../assets/images/trending3.png";
import trending4 from "../assets/images/trending4.png";
import gfpt from "../assets/images/gfpt.jpeg";
import security from "../assets/images/security.png";

export {
  engFlag,
  thaiFlag,
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  newHomeBg,
  about,
  homeBg,
  homeBg2,
  homeBg3,
  gfpt,
  security,
};
export const trendingImgs = [trending1, trending2, trending3, trending4];
