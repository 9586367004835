import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import { FcPhoneAndroid, FcLock, FcPortraitMode } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import loginIcon from "../../assets/images/login-icon.png";
import IconInput from "../../components/forms/IconInput";
import { ROUTE } from "../../constants/app_routes";
import { REGISTER } from "../../graphql/mutations/auth";
import CustomErrorMessage, {
  showErrorToast,
  showSuccessToast,
} from "../../components/common/CustomErrorMessage";
import { currentBranch } from "../../constants/app_constants";

const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const [userInfo, setUserInfo] = useState({
    username: "",
    password: "",
    passwordConfirm: "",
    nickname: "",
    branch: currentBranch,
  });

  useEffect(() => {
    if (auth.isLogin) navigate(ROUTE.HOME);
  }, [auth.isLogin, navigate]);

  const onChange = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const [handleRegister, { loading, error }] = useMutation(REGISTER, {
    variables: { input: userInfo },
    onCompleted: (data) => {
      if (data.registerMember) {
        showSuccessToast("registerSuccessText");
        navigate(ROUTE.LOGIN);
      }
    },
    onError: (error) => {
      showErrorToast(error.message);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await handleRegister();
  };

  return (
    <div className="register">
      <div className="image">
        <img src={loginIcon} alt="user" className="img" />
        <Container>
          <p className="text-center">
            {error && <CustomErrorMessage messageKey={error.message} />}
          </p>
          <Form onSubmit={handleSubmit}>
            <IconInput
              icon={<FcPhoneAndroid size={20} />}
              placeholder={t("pleaseEnterAccountNo")}
              name="username"
              value={userInfo.username}
              type="text"
              onChange={onChange}
            />
            <IconInput
              icon={<FcLock size={20} />}
              placeholder={t("กรุณาใส่รหัสผ่าน")}
              name="password"
              value={userInfo.password}
              type="password"
              onChange={onChange}
            />
            <IconInput
              icon={<FcLock size={20} />}
              placeholder={t("pleaseConfirmPassword")}
              name="passwordConfirm"
              value={userInfo.passwordConfirm}
              type="password"
              onChange={onChange}
            />
            <IconInput
              icon={<FcPortraitMode size={20} />}
              placeholder={t("pleaseEnterNickname")}
              name="nickname"
              value={userInfo.nickname}
              type="nickname"
              onChange={onChange}
            />
            <div className="d-grid gap-2 mb-2">
              <Button type="submit" variant="success" disabled={loading}>
                {loading ? (
                  <Spinner size="sm" animation="border" variant="light" />
                ) : null}{" "}
                {t("confirm")}
              </Button>
            </div>
            <div className="d-flex justify-content-center">
              <span className="mr-3">{t("haveAccount")} </span>
              <Link to={ROUTE.LOGIN}>
                <p className="ml-3">{t("login")}</p>
              </Link>
            </div>
          </Form>
        </Container>
      </div>
    </div>
  );
};

export default Register;
