import { Placeholder, Card } from "react-bootstrap";

const CurrentGameSkeleton = () => {
  return (
    <Placeholder as={Card.Text} animation="glow">
      <Placeholder md={10} />
      <Placeholder xs={10} />
    </Placeholder>
  );
};

export default CurrentGameSkeleton;
