import React from "react";
import { useTranslation } from "react-i18next";

import { EqualIcon, PlusIcon } from "../../../constants/icon_path";

const LotteryResultItem = ({ data }) => {
  const { t } = useTranslation();
  const sum = data?.result.reduce(
    (accumulator, currentValue) => accumulator + currentValue
  );

  const renderTextResult = (result) => {
    const isOdd = result % 2;
    const left = result > 10 ? t("high") : t("low");
    const right = isOdd === 0 ? t("double") : t("single");

    return <span>{`(${left},${right})`}</span>;
  };

  return (
    <div className="d-flex mb-2 result-item">
      <p>
        {data?.lotteryNo}
        {t("period")}
      </p>
      <div className="lottery-result">
        {data?.result?.map((number, i) => {
          return (
            <React.Fragment key={i}>
              <div className="circle">
                <p className="text-center">{number}</p>
              </div>
              {i < 2 ? <PlusIcon color="red" size={20} /> : null}
            </React.Fragment>
          );
        })}
        <EqualIcon color="red" size={20} />
        <div className="circle equal">
          <p className="text-center">{sum}</p>
        </div>{" "}
        <div className="result-text">
          <p className="text-center text-danger">{renderTextResult(sum)}</p>
        </div>
      </div>
    </div>
  );
};

export default LotteryResultItem;
