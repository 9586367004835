import { Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { removeMessageImage, uploadMessageImage } from "../redux/messageSlice";
import { SendIcon } from "../../../constants/icon_path";

const UploadImageButton = ({ handleClose, sendMessage, memberProfile }) => {
  const dispatch = useDispatch();
  const messageState = useSelector((state) => state.message);
  const { uploadLoading, file, message } = messageState;

  const handleSend = async () => {
    const { payload } = await dispatch(uploadMessageImage({ handleClose }));
    if (payload.success) {
      dispatch(removeMessageImage());
      await sendMessage({
        variables: {
          input: {
            member: memberProfile?.id,
            message: message,
            isAdmin: false,
            isImage: true,
            imageUrl: payload.url,
          },
        },
      });
    }
  };

  return (
    <Button
      type="button"
      onClick={handleSend}
      style={{ marginLeft: "5px" }}
      disabled={!file}
    >
      {uploadLoading ? (
        <Spinner variant="light" animation="border" size="sm" />
      ) : (
        <SendIcon size={20} />
      )}
    </Button>
  );
};

export default UploadImageButton;
