import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";

import BaseLayout from "../../components/layout";

const ProfitRecord = () => {
  const { t } = useTranslation();
  return (
    <BaseLayout>
      <div className="profile">
        <Card>
          <div className="page-link d-flex justify-content-between">
            <span>{t("totalInvestment")}</span>
            <span>3434343</span>
          </div>
          <hr />
          <div className="page-link d-flex justify-content-between">
            <span>{t("totalIncome")}</span>
            <span>33344</span>
          </div>
          <hr />
          <div className="page-link d-flex justify-content-between">
            <span>{t("totalProfitAndLoss")}</span>
            <span>33344</span>
          </div>
        </Card>
      </div>
    </BaseLayout>
  );
};

export default ProfitRecord;
