import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import BaseLayout from "../../components/layout";
import Header from "../../components/layout/headers/Header";
import CustomErrorMessage, {
  showErrorToast,
  showSuccessToast,
} from "../../components/common/CustomErrorMessage";
import IconInput from "../../components/forms/IconInput";
import { FcLock, FcPhoneAndroid } from "react-icons/fc";
import {
  UPDATE_PROFILE,
  UPDATE_PROFILE_IMAGE,
} from "../../graphql/mutations/profile";
import { CameraColorIcon } from "../../constants/icon_path";
import { useProfileQuery } from "../../graphql/apollo/query-hooks";
import defaulAvatar from "../../assets/images/avatar.jpeg";
import CustomModal from "../../components/bootstrap/CustomModal";
import UploadImageButton from "../../components/shared/UploadImageButton";
import UploadProfileImage from "../../components/shared/UploadProfileImage";

const UpdateProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({
    nickname: null,
    password: "",
  });
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);

  const handleChange = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const [updateProfile, { loading, error }] = useMutation(UPDATE_PROFILE, {
    variables: {
      input: userInfo,
    },
    onCompleted: (data) => {
      if (data.updateProfile) {
        showSuccessToast();
        navigate(-1);
        setUserInfo({
          nickname: null,
          password: "",
        });
      }
    },
    onError: (err) => {
      showErrorToast(err.message);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await updateProfile();
  };

  const { memberProfile, refetch } = useProfileQuery();
  const [updateProfileImage] = useMutation(UPDATE_PROFILE_IMAGE, {
    onCompleted: () => {
      refetch();
    },
  });

  const handleUpload = () => setShowModal(true);

  const currentAvatar = memberProfile?.profileImage ?? defaulAvatar;

  const requireInput = !userInfo.nickname;

  return (
    <BaseLayout>
      <CustomModal
        show={showModal}
        handleClose={handleCloseModal}
        size="md"
        title={
          <UploadImageButton
            handleClose={handleCloseModal}
            updateProfileImage={updateProfileImage}
          />
        }
      >
        <UploadProfileImage currentAvatar={currentAvatar} />
      </CustomModal>
      <Header title={t("updateProfile")} />
      <Card.Body>
        <div className="security">
          <h1 className="text-center">{t("updateProfile")}</h1>
          <div className="user-profile__avatar">
            <img src={currentAvatar} alt="avatar" />
            <CameraColorIcon onClick={handleUpload} size={30} />
          </div>
          <div className="m-5">
            <Form onSubmit={handleSubmit}>
              <IconInput
                icon={<FcPhoneAndroid size={20} />}
                placeholder={t("pleaseEnterNickname")}
                name="nickname"
                value={userInfo.nickname ?? memberProfile?.nickname}
                type="text"
                onChange={handleChange}
              />
              <IconInput
                icon={<FcLock size={20} />}
                placeholder={t("password")}
                name="password"
                value={userInfo.password}
                type="password"
                onChange={handleChange}
              />

              <p className="text-center">
                {error && <CustomErrorMessage messageKey={error.message} />}
              </p>
              <div className="d-grid gap-2 mb-2">
                <Button
                  type="submit"
                  variant="success"
                  disabled={loading || requireInput}
                >
                  {loading ? (
                    <Spinner size="md" animation="border" variant="light" />
                  ) : null}{" "}
                  {t("confirm")}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Card.Body>
    </BaseLayout>
  );
};

export default UpdateProfile;
