import { gql } from "@apollo/client";
import { BET_INFO, TRANSACTION_RECORD_INFO } from "../fragments";

export const TOPUP_RECORD = gql`
  query topupRecord {
    topupRecord {
      ...transactionRecordInfo
    }
  }
  ${TRANSACTION_RECORD_INFO}
`;

export const WITHDRAW_RECORD = gql`
  query withdrawRecord {
    withdrawRecord {
      ...transactionRecordInfo
    }
  }
  ${TRANSACTION_RECORD_INFO}
`;

export const INVESTMENT_PROFIT = gql`
  query memberInvestmentProfit {
    memberInvestmentProfit {
      profit
      investResult
    }
  }
`;

export const INVESTMENT_RECORD = gql`
  query betRecord {
    betRecord {
      ...betInfo
    }
  }
  ${BET_INFO}
`;
