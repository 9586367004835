import { Placeholder, Card, Col, Row } from "react-bootstrap";

const BetSkeleton = () => {
  return (
    <Row className="p-2">
      <Col xs="3" md="2">
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder
            style={{
              height: "5rem",
              width: "5rem",
              borderRadius: "7rem",
              marginTop: "2.5rem",
              marginLeft: "2rem",
            }}
            xs={10}
            size="lg"
          />
        </Placeholder>
      </Col>
      <Col xs="9" md="10">
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={4} size="sm" style={{ height: "2rem" }} />
          <Placeholder
            style={{ height: "5rem", marginTop: "1rem" }}
            xs={10}
            size="lg"
          />
        </Placeholder>
      </Col>
    </Row>
  );
};

export default BetSkeleton;
