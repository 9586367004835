import { Placeholder, Card } from "react-bootstrap";

const TrendingSkeleton = () => {
  return (
    <Placeholder as={Card.Text} animation="glow">
      <Placeholder
        className="m-2"
        style={{ height: "4rem" }}
        xs={12}
        size="sm"
      />
      <Placeholder
        className="m-2"
        style={{ height: "4rem" }}
        xs={12}
        size="sm"
      />
      <Placeholder
        className="m-2"
        style={{ height: "4rem" }}
        xs={12}
        size="sm"
      />
      <Placeholder
        className="m-2"
        style={{ height: "4rem" }}
        xs={12}
        size="sm"
      />
      <Placeholder
        className="m-2"
        style={{ height: "4rem" }}
        xs={12}
        size="sm"
      />
      <Placeholder
        className="m-2"
        style={{ height: "4rem" }}
        xs={12}
        size="sm"
      />{" "}
      <Placeholder
        className="m-2"
        style={{ height: "4rem" }}
        xs={12}
        size="sm"
      />
    </Placeholder>
  );
};

export default TrendingSkeleton;
