import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import CustomModal from "../../../components/bootstrap/CustomModal";
import UploadImageButton from "./UploadImageButton";
import UploadMessageImage from "./UploadMessageImage";
import { onChangeMessage } from "../redux/messageSlice";
import { ImageIcon, SendIcon } from "../../../constants/icon_path";
import { currentBranch } from "../../../constants/app_constants";

const ChatInput = ({ sendMessage, memberProfile }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const dispatch = useDispatch();
  const messageState = useSelector((state) => state.message);
  const { message, isAdmin, isImage, imageUrl } = messageState;

  const handleChangeMessage = (message) => dispatch(onChangeMessage(message));

  const handleSubmit = (e) => {
    sendMessage({
      variables: {
        input: {
          member: memberProfile?.id,
          message,
          isAdmin,
          isImage,
          imageUrl,
          branch: currentBranch,
        },
      },
    });
    e.preventDefault();
  };

  const handleClickImage = () => {
    setShowModal(true);
  };

  return (
    <div className="chat_input">
      <CustomModal
        show={showModal}
        handleClose={handleCloseModal}
        size="md"
        title={
          <UploadImageButton
            message={message}
            handleClose={handleCloseModal}
            sendMessage={sendMessage}
            memberProfile={memberProfile}
          />
        }
      >
        <UploadMessageImage />
      </CustomModal>
      <div className="background-layer">
        <div className="m-2">
          <Row>
            <Col xs="2" md="1">
              <div className="emoji">
                <ImageIcon onClick={handleClickImage} size={25} color="grey" />
              </div>
            </Col>
            <Col xs="10" md="11" className="p-2">
              <Form
                onSubmit={handleSubmit}
                className="d-flex justify-content-between"
              >
                <Form.Control
                  type="text"
                  placeholder={t("enterMessage")}
                  onChange={(e) => handleChangeMessage(e.target.value)}
                  value={message}
                />
                <Button
                  type="submit"
                  style={{ marginLeft: "5px" }}
                  disabled={!message}
                >
                  <SendIcon size={20} />
                </Button>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

ChatInput.prototype = {
  sendMessage: PropTypes.func,
};

export default ChatInput;
