import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  message: "",
  imageUrl: "",
  isAdmin: false,
  isImage: false,
  uploadLoading: false,
  uploadError: null,
  file: "",
  uploadPreview: null,
};

export const uploadMessageImage = createAsyncThunk(
  "message/upload",
  async ({ handleClose }, thunkAPI) => {
    const { file } = thunkAPI.getState().message;
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_REST_ENDPOINT}/uploadMessageImage`,
        {
          file,
        }
      );
      handleClose();
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    onChangeMessage: (state, { payload }) => {
      state.message = payload;
    },
    clearMessage: (state) => {
      state.file = initialState.file;
      state.uploadPreview = initialState.uploadPreview;
      state.message = initialState.message;
      state.isAdmin = initialState.isAdmin;
      state.isImage = initialState.isImage;
    },
    setMessageImageFile: (state, { payload }) => {
      state.file = payload;
    },
    setMessageImagePreview: (state, { payload }) => {
      state.uploadPreview = payload;
    },
    removeMessageImage: (state) => {
      state.file = initialState.file;
      state.uploadPreview = initialState.uploadPreview;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadMessageImage.pending, (state) => {
        state.uploadLoading = true;
      })
      .addCase(uploadMessageImage.fulfilled, (state) => {
        state.uploadLoading = false;
      })
      .addCase(uploadMessageImage.rejected, (state, action) => {
        state.uploadLoading = false;
        state.uploadError = action.payload;
      });
  },
});

export const {
  onChangeMessage,
  clearMessage,
  setMessageImageFile,
  setMessageImagePreview,
  removeMessageImage,
} = messageSlice.actions;

export default messageSlice.reducer;
