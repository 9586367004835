import { useTranslation } from "react-i18next";
import RowFormGroup from "../../../components/forms/RowFormGroup";

const BankForm = ({ memberProfile, account, handleChange, disabled }) => {
  const { t } = useTranslation();
  return (
    <>
      <RowFormGroup
        label={t("bankName")}
        name="bankName"
        value={account.bankName}
        placeholder={
          disabled ? memberProfile?.account?.bankName : t("bankName")
        }
        onChange={handleChange}
        type="text"
        labelCol={3}
        inputCol={9}
        disabled={disabled}
      />
      <RowFormGroup
        label={t("accountNo")}
        name="accountNo"
        placeholder={
          disabled ? memberProfile?.account?.accountNo : t("accountNo")
        }
        onChange={handleChange}
        type="text"
        value={account.accountNo}
        labelCol={3}
        inputCol={9}
        disabled={disabled}
      />
      <RowFormGroup
        label={t("accountName")}
        name="accountName"
        placeholder={
          disabled ? memberProfile?.account.accountName : t("accountName")
        }
        onChange={handleChange}
        type="text"
        value={account.accountName}
        labelCol={3}
        inputCol={9}
        disabled={disabled}
      />
    </>
  );
};

export default BankForm;
