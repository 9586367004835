import { useNavigate } from "react-router-dom";

import { ChevronBackIcon } from "../../../constants/icon_path";

const Header = ({ title, onGoback }) => {
  const navigate = useNavigate();

  const goBack = () => {
    if (onGoback) {
      onGoback();
    } else {
      navigate(-1);
    }
  };
  return (
    <div className="default-header">
      <div>
        <ChevronBackIcon size={25} onClick={() => goBack()} />
      </div>{" "}
      <div className="text-dark default-header__title">{title}</div>
      <div></div>
    </div>
  );
};

export default Header;
