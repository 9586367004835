import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";

import { trendingImgs } from "../../../constants/image_path";
import { numberWithCommas } from "../../../utils/math";
import { useBetQuery } from "../../../graphql/apollo/query-hooks";
import TrendingSkeleton from "./sketleton/TrendingSkeleton";

const MarketTrending = () => {
  const { t } = useTranslation();
  const { getBetList, loadingBets } = useBetQuery();

  const renderMemberId = (id) => {
    const sub = id.substring(3);
    const hideId = `**** ${sub}`;
    return hideId;
  };

  return (
    <div className="home__trending pb-3">
      <h3 className="text-center m-3">{t("marketTrend")}</h3>
      <Card className="p-1">
        {loadingBets ? (
          <TrendingSkeleton />
        ) : (
          <marquee direction="up" height="300" scrollamount="5">
            {getBetList?.map((item) => {
              const randomNumber = Math.floor(Math.random() * 4);

              return (
                <>
                  <div className="home__trending_item" key={item.id}>
                    <h4> {renderMemberId(item.member.memberId)}</h4>
                    <div>
                      <h4>{item?.country?.name}</h4>
                      <h4 className="text-warning mt-1">
                        +{numberWithCommas(item.amount)}
                      </h4>
                    </div>
                    <img src={trendingImgs[randomNumber]} alt="trend" />
                  </div>
                  <hr />
                </>
              );
            })}
          </marquee>
        )}
      </Card>
    </div>
  );
};

export default MarketTrending;
