import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import { engFlag, thaiFlag } from "../../constants/image_path";

const SwitchLanguage = ({ showText = true }) => {
  const { t, i18n } = useTranslation();

  const onChangeLanguage = (ln) => {
    localStorage.setItem("language", ln);
    i18n.changeLanguage(ln);
  };

  return (
    <div className="change-language">
      {showText ? <p>{t("switchLanguage")}: </p> : null}

      <Dropdown>
        <Dropdown.Toggle variant="success">
          <img
            src={i18n.language === "th" ? thaiFlag : engFlag}
            width={25}
            alt="flag"
          />{" "}
          {i18n.language === "th" ? "ไทย" : "English"}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => onChangeLanguage("en")} href="#">
            <img src={engFlag} width={20} alt="flag" /> English
          </Dropdown.Item>
          <Dropdown.Item onClick={() => onChangeLanguage("th")} href="#">
            <img src={thaiFlag} width={20} alt="flag" /> ไทย
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default SwitchLanguage;
