import { useTranslation } from "react-i18next";

import RowFormGroup from "../../../components/forms/RowFormGroup";

const CryptoForm = ({ memberProfile, account, handleChange, disabled }) => {
  const { t } = useTranslation();

  return (
    <>
      <RowFormGroup
        label={t("network")}
        name="network"
        value={account.crypto.network}
        placeholder={
          disabled ? memberProfile?.crypto.network : account.crypto.network
        }
        onChange={handleChange}
        type="text"
        labelCol={3}
        inputCol={9}
        disabled={disabled}
      />
      <RowFormGroup
        label={t("cryptoAddress")}
        name="address"
        value={account.crypto.address}
        placeholder={
          disabled ? memberProfile?.crypto.address : account.crypto.address
        }
        onChange={handleChange}
        type="text"
        labelCol={3}
        inputCol={9}
        disabled={disabled}
      />
    </>
  );
};

export default CryptoForm;
