import { useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import BaseLayout from "../../components/layout";
import RoomHeader from "./components/RoomHeader";
import { ENTER_ROOM, GET_ROOM_LIST } from "../../graphql/queries/game";
import RequireModal from "./components/RequireModal";
import { ROUTE } from "../../constants/app_routes";
import {
  setEnterRoom,
  toggleRequireMinimumModal,
  toggleRequireModal,
} from "./redux/gameSlice";
import RequireMinimumModal from "./components/RequireMinimumModal";
import { useProfileQuery } from "../../graphql/apollo/query-hooks";
import { showErrorToast } from "../../components/common/CustomErrorMessage";
import RoomCard from "./components/RoomCard";
import { MonitorIcon, QuestionIcon } from "../../constants/icon_path";
import CustomOffCanvas from "../../components/bootstrap/CustomOffCanvas";
import { currentBranch } from "../../constants/app_constants";

const GameRooms = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [minimum, setMinimum] = useState(0);
  const { enterRoom } = useSelector((state) => state.game);
  const { showRequireModal, showRequireMinimumModal } = useSelector(
    (state) => state.game
  );
  const { data: { roomList } = {} } = useQuery(GET_ROOM_LIST, {
    variables: { branch: currentBranch },
  });
  const { memberProfile } = useProfileQuery();
  const [onEnterRoom, { loading }] = useLazyQuery(ENTER_ROOM, {
    onError(error) {
      showErrorToast(error.message);
    },
    onCompleted(data) {
      if (data.enterRoom) {
        navigate(ROUTE.ASSET, {
          state: { room: data.enterRoom, country: state.country },
        });
        dispatch(setEnterRoom({ room: "", password: "" }));
        dispatch(toggleRequireModal());
      }
    },
  });

  const handleEnterRoom = (room) => {
    if (room.isRequireMinimumBalance) {
      if (memberProfile.balance < room.minimumBalance) {
        setMinimum(room.minimumBalance);
        dispatch(toggleRequireMinimumModal());
        return;
      }
    }
    if (room.isRequirePassword) {
      dispatch(setEnterRoom({ ...enterRoom, room: room.id }));
      dispatch(toggleRequireModal());
      return;
    }
    navigate(ROUTE.ASSET, { state: { room, country: state.country } });
  };
  const handleClose = () => dispatch(toggleRequireModal());
  const handleCloseMinimum = () => dispatch(toggleRequireMinimumModal());

  const handleConfirm = () => {
    onEnterRoom({
      variables: { room: enterRoom.room, password: enterRoom.password },
    });
  };

  const handleChange = (e) =>
    dispatch(setEnterRoom({ ...enterRoom, password: e.target.value }));
  const [show, setShow] = useState(false);

  const handleCloseO = () => setShow(false);
  const handleShowO = () => {
    setShow(true);
  };
  return (
    <BaseLayout>
      <RequireModal
        show={showRequireModal}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        handleChange={handleChange}
        loading={loading}
      />
      <RequireMinimumModal
        show={showRequireMinimumModal}
        handleClose={handleCloseMinimum}
        balance={minimum}
      />
      <div className="room">
        <CustomOffCanvas
          show={show}
          handleClose={handleCloseO}
          title="วิธีการเล่น"
          placement="bottom"
        >
          <p>28 วิธีการซื้อ</p>
          <p>
            วิธีทำการซื้อขนาด : ตัวเลข 0-13 มีขนาดเล็กและ ตัวเลข 14-27
            มีขนาดใหญ่ ทำการซื้อเดี่ยวและคู่: หมายเลข 1, 3, 5, ~27 เป็นเลขเดี่ยว
            และหมายเลข 0, 2, 4~26 เป็นเลขคู่
          </p>
          <p>
            การลงทุนที่คุ้มค่าที่สุด: [เล็กมาก 0-5] [ใหญ่มาก 22-27]
            การซื้อแบบผสม: หมายเลข 14, 16, ~26 เป็นคู่ใหญ่ หมายเลข 0, 2, 4, ~12
            เป็นคู่เล็ก หมายเลข 15, 17, ~27 เป็นคู่ใหญ่ หมายเลข 1, 3, 5, ~13
            เป็นคู่เล็ก วิธีการวางตำแหน่ง (วิธีตัวเลขเดียว): เลือกตัวเลขตั้งแต่
            0-27
          </p>
          <p>
            1. ครั้งแรก ~ ครั้งที่สาม ซื้อครั้งแรก~ซื้อครั้งที่สาม : ซื้อรอบแรก
            (ร้อย), รอบที่สอง (หลักสิบ), รอบที่สาม (หนึ่ง): หมายถึง
            หมายเลขที่ทำการซื้อแต่ละรอบและหมายเลขที่ออก, ลำดับการออกเลข
            ถ้าเลขเท่ากันก็ถือเป็นรางวัลชนะ ถ้าออกครั้งแรก เป็นเลข 8
            ผู้ที่ทำการซื้อรอบแรกเป็นเลข 8 ถือว่าได้รางวัลและกรณีอื่นๆ
            ถือว่าไม่ได้รับรางวัล
          </p>
          <p>
            2. การลงทุนซ้ำ ขนาด:
            ตามผลรวมของตัวเลขของรอบแรกถึงรอบที่สามของการเดิมพันเดี่ยวที่สอดคล้องกันนั้นมากกว่าหรือเท่ากับ
            14 และถือว่าน้อยหากน้อยกว่าหรือเท่ากับ 13 คี่และคู่:
            จากผลรวมของตัวเลขของการซื้อรอบแรกถึงรอบที่สามของการเดิมพันเดี่ยวที่เกี่ยวข้อง
            เลขคี่คือคี่ และเลขคู่คือเลขคู่ ลำดับใหญ่:
            มูลค่ารวมของจำนวนการซื้อที่ดึงจากรอบแรกถึงรอบที่สามของลงทุนซื้อเดี่ยวที่สอดคล้องกัน:
            15, 17, 19, 21, 23, 25, 27
            เป็นคำสั่งใหญ่และการเดิมพันตัวเลขในช่วงนี้ ถือเป็น [ออเดอร์ใหญ่]
            รับรางวัล และสถานการณ์ที่เหลือถือว่าไม่ได้รับรางวัล
          </p>
          <p>
            ลำดับเล็ก:
            ผลรวมของหมายเลขการซื้อที่สุ่มจากการซื้อรอบแรกถึงรอบที่สามของการเดิมพันเดี่ยวที่สอดคล้องกัน:
            01, 03, 05, 07, 09, 11 และ 13 เป็นลำดับเล็ก ๆ
            และการลงทุนตัวเลขในช่วงนี้คือ ถือเป็น [คำสั่งเล็กๆ] ได้รับรางวัล
            และสถานการณ์ที่เหลือถือว่าไม่ได้รับรางวัล การซื้อขนาดใหญ่แบบ2ครั้ง :
            ผลรวมของตัวเลขที่สุ่มจากการซื้อรอบแรกถึงรอบที่สามของการลงทุนเดี่ยวที่สอดคล้องกัน:
            14, 16, 18, 20, 22, 24 และ 26 เป็นบิ๊กดับเบิ้ล
            และการลงทุนในช่วงตัวเลขนี้ถือว่าได้รับ [บิ๊กดับเบิล ] รับรางวัล 2
            เท่า และสถานการณ์ที่เหลือถือว่าไม่ได้รับรางวัล สมอลล์ ดับเบิ้ล:
            ผลรวมของตัวเลขที่สุ่มตั้งแต่รอบแรกถึงรอบที่สามของการลงทุนเดี่ยวที่เกี่ยวข้อง:
            00, 02, 04, 06, 08, 10 และ 12 เป็นคู่ขนาดเล็ก
            และการเดิมพันตัวเลขในช่วงนี้ถือว่า เป็น [คู่เล็ก] ได้รับรางวัล
            และสถานการณ์ที่เหลือถือว่าไม่ได้รับรางวัล
          </p>
          <p>
            สูงมาก:
            ผลรวมของตัวเลขที่สุ่มรอบแรกถึงรอบที่สามของการลงทุนเดี่ยวที่เกี่ยวข้อง:
            23, 24, 25, 26 และ 27 ถือว่าได้รับรางวัล
            และการลงทุนตัวเลขในช่วงนี้ถือว่าชนะรางวัล [มาก] และที่เหลือ
            ถือว่าไม่ชนะ น้อยมาก:
            ผลรวมของตัวเลขที่สุ่มตั้งแต่รอบแรกถึงรอบที่สามของการลงทุนเดี่ยวที่เกี่ยวข้อง:
            00, 01, 02, 03 และ 04 นั้นน้อยมาก และการเดิมพันตัวเลขในช่วงนี้ถือว่า
            [เล็กมาก] ถือว่าได้รับรางวัล และที่เหลือ ถือว่าไม่ชนะ
          </p>
          <p>
            3. มูลค่ารวม
            ตามผลรวมของหมายเลขที่สุ่มตั้งแต่รอบแรกถึงรอบที่สามของการลงทุนเดี่ยวที่เกี่ยวข้อง:
            เดิมพันหมายเลขใด ๆ ตั้งแต่ 0 ถึง 27
            เมื่อหมายเลขที่ลงทุนเท่ากับผลรวมของหมายเลขทั้งสามของหมายเลขที่ทำการซื้อ
            ถือว่าได้กำไร นอกจากนั้นถือว่าไม่ได้รางวัล
            4.นโยบายทางกฎหมายที่เกี่ยวข้อง: ตามนโยบายกฎหมายของประเทศ
            การถอนเงินครั้งเดียวเกิน 100,000 บาท ต้องเสียภาษีเงินได้บุคคลธรรมดา
            17%
          </p>
        </CustomOffCanvas>
        <RoomHeader title={"การเลือกห้อง"} />
        <div className="room__body">
          <div className="room__body_card">
            <div className="room__body_card-instruction">
              <div className="d-flex">
                <MonitorIcon size={20} color="orange" />{" "}
                <h3>การเลือกห้องเล่นเกม</h3>
              </div>
              <div
                className="room__body_card-instruction-btn pointer"
                onClick={() => handleShowO()}
              >
                <QuestionIcon size={20} color="white" />
                <h3 className="text-white">วิธีการเลือก</h3>
              </div>
            </div>
            <p>สำหรับผู้เล่นที่แตกต่างกัน มีห้องให้เลือกหลากหลาย</p>
            {roomList?.map((room, i) => {
              return (
                <RoomCard
                  handleEnterRoom={handleEnterRoom}
                  room={room}
                  key={i + 1}
                  i={i}
                />
              );
            })}
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default GameRooms;
