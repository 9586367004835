import { image1, image2, image3, image4, image5, image6 } from "./image_path";

const TRANSACTION_NUMBERS = [7004, 5064, 9077, 7865, 9878, 9776];
const BGS = [image1, image2, image3, image4, image5, image6];
const MAX_BET = 1000000;
const currentBranch = process.env.REACT_APP_BRANCH;

export const ACCOUNT_TYPE = {
  BANK: "BANK",
  CRYPTO: "CRYPTO",
};

export { BGS, TRANSACTION_NUMBERS, MAX_BET, currentBranch };
