import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useTranslation } from "react-i18next";

function FilterTransactionDropdown({ title }) {
  const { t } = useTranslation();

  return (
    <DropdownButton title={title} variant="text">
      <Dropdown.Item>{t("ทุกประเภท")}</Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item>{t("withdraw")}</Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item>{t("topup")}</Dropdown.Item>
    </DropdownButton>
  );
}

export default FilterTransactionDropdown;
