import { Button, Modal } from "react-bootstrap";

const CustomModal = ({ title, children, handleClose, show, size = "sm" }) => {
  return (
    <Modal className="fade" show={show} onHide={handleClose} size={size}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <Button variant="" className="btn-close" onClick={handleClose}></Button>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default CustomModal;
