import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Footer from "../../components/layout/footers/Footer";
import UsdtRed from "../../assets/images/usdt-red.jpeg";
import Bank from "../../assets/images/atm.jpg";
import { ROUTE } from "../../constants/app_routes";
import BaseLayout from "../../components/layout";
import { formatCurrency } from "../../utils/math";
import { useProfileQuery } from "../../graphql/apollo/query-hooks";

const Topup = () => {
  const { t } = useTranslation();
  const { memberProfile } = useProfileQuery();

  return (
    <BaseLayout>
      <div className="topup">
        <Card bg="success" style={{ height: "60px" }}>
          <Card.Body>
            <h3 className="balance-text text-center">
              {t("balance")}: {formatCurrency(memberProfile?.balance ?? 0)}
            </h3>
          </Card.Body>
        </Card>
        <Card>
          <Link
            to={ROUTE.TOPUP_BANK_CARD}
            state={{
              logo: UsdtRed,
              name: t("bankPayment"),
              isBank: true,
            }}
          >
            <div className="account-type d-flex">
              <img className="logo-type img-thumbnail" src={Bank} alt="usdt" />
              <h3>{t("bankCard")}</h3>
            </div>
          </Link>
        </Card>
        <div>
          <div className="d-flex mt-3 justify-content-center">
            <Link to={ROUTE.TOPUP_RECORD}>
              <Button variant="info" className="text-white">
                {t("viewCardRecord")}
              </Button>
            </Link>
            <Link to={ROUTE.SUPPORT}>
              <Button variant="primary">{t("supportText")}</Button>
            </Link>
          </div>
        </div>
        <Footer />
      </div>
    </BaseLayout>
  );
};

export default Topup;
