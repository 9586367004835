import { CheckCircleIcon } from "../../../constants/icon_path";

const LanguageItem = ({ flag, title, onClick, isSelected }) => {
  return (
    <div onClick={onClick}>
      <div className="settings-menu d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <img src={flag} width={30} height={20} alt="flag" />
          <p className="link-title">{title}</p>
        </div>
        {isSelected && (
          <CheckCircleIcon color="green" className="arrow-icon" size={25} />
        )}
      </div>
    </div>
  );
};

export default LanguageItem;
