import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { AiFillHome } from "react-icons/ai";

import { ROUTE } from "../../../constants/app_routes";

const HeaderAsset = () => {
  const { t } = useTranslation();

  return (
    <div className="asset-header d-flex  align-items-center">
      <span className="assets">
        <Link to={ROUTE.HOME}>
          <Button className="m-2" variant="danger">
            <AiFillHome size={15} /> {t("lobby")}
          </Button>
        </Link>
      </span>
    </div>
  );
};

export default HeaderAsset;
