import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import { ROUTE } from "../../../constants/app_routes";
import { useCountryQuery } from "../../../graphql/apollo/query-hooks";
import CountrySkeleton from "./sketleton/CountrySkeleton";

const CountryList = () => {
  const { getCountryList, loading } = useCountryQuery();

  return (
    <Container className="mt-2 pb-5 home__country-list">
      <Card bg="primary">
        {loading ? (
          <CountrySkeleton />
        ) : (
          <Row>
            {getCountryList?.map((country) => {
              return (
                <Col md="6" xs="6" key={country.id}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={ROUTE.GAME_ROOMS}
                    state={{ country: country }}
                  >
                    <div className="item">
                      <img
                        src={country.imageUrl}
                        alt="country"
                        className="flag-img img-thumbnail"
                      />
                      <h4>{country.name}</h4>
                    </div>
                  </Link>
                </Col>
              );
            })}
          </Row>
        )}
      </Card>
    </Container>
  );
};

export default CountryList;
