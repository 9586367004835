import { useDispatch } from "react-redux";
import { DeleteBackIcon, KeyboardHideIcon } from "../../constants/icon_path";
import NumberPadButton from "./NumberPadButton";
import {
  removeSecurityPassword,
  setSecurityPassword,
} from "../../pages/profile/redux/profileSlice";

const CustomNumberPad = ({ handleClose }) => {
  const dispatch = useDispatch();

  const handleClick = (value) => dispatch(setSecurityPassword(value));
  const handleRemove = () => dispatch(removeSecurityPassword());

  return (
    <div className="number-pad">
      <NumberPadButton title="1" onClick={() => handleClick("1")} />
      <NumberPadButton title="2" onClick={() => handleClick("2")} />
      <NumberPadButton title="3" onClick={() => handleClick("3")} />
      <NumberPadButton title="4" onClick={() => handleClick("4")} />
      <NumberPadButton title="5" onClick={() => handleClick("5")} />
      <NumberPadButton title="6" onClick={() => handleClick("6")} />
      <NumberPadButton title="7" onClick={() => handleClick("7")} />
      <NumberPadButton title="8" onClick={() => handleClick("8")} />
      <NumberPadButton title="9" onClick={() => handleClick("9")} />
      <NumberPadButton title={<KeyboardHideIcon />} onClick={handleClose} />
      <NumberPadButton title="0" onClick={() => handleClick("0")} />
      <NumberPadButton title={<DeleteBackIcon />} onClick={handleRemove} />
    </div>
  );
};

export default CustomNumberPad;
