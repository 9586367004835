import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Countdown from "react-countdown";
import moment from "moment";

import CurrentGameSkeleton from "../components/sketleton/CurrentGameSkeleton";
import { TimeIcon } from "../../../constants/icon_path";

const CurrentGameCard = ({ currentGame, refetch, loadingCurrent }) => {
  const { t } = useTranslation();

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      refetch();
      setTimeout(() => {
        if (currentGame && currentGame.status === "running") {
          window.location.reload(false);
        }
      }, 2000);

      return (
        <p className="text-success">
          <TimeIcon /> 00 {t("minutes")} 00 {t("seconds")}
        </p>
      );
    } else {
      return (
        <h6 className="text-danger countdown">
          <TimeIcon /> {minutes}
          {t("minutes")}: {seconds}
          {t("seconds")}
        </h6>
      );
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentGame.status === "closed") {
        refetch();
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [currentGame.status, refetch]);

  const countdownTime = moment(currentGame?.createdAt).add(5, "m").toDate();

  return loadingCurrent ? (
    <CurrentGameSkeleton />
  ) : (
    <div className="text-center">
      <p className="m-2">
        {t("distance")} {currentGame?.lotteryNo} {t("periodLottery")}
      </p>
      <Countdown date={countdownTime} renderer={renderer} autoStart={true} />
    </div>
  );
};

export default CurrentGameCard;
