import { FormGroup, Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Resizer from "react-image-file-resizer";

import {
  clearProfileImage,
  setProfileImageFile,
  setProfileImagePreview,
} from "../../pages/profile/redux/profileSlice";
import { TrashIcon } from "../../constants/icon_path";

const UploadProfileImage = ({ currentAvatar }) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const { uploadPreview } = profile;

  const handleImage = (e) => {
    const file = e.target.files[0];
    dispatch(setProfileImagePreview(window.URL.createObjectURL(file)));
    Resizer.imageFileResizer(file, 720, 500, "JPEG", 100, 0, async (uri) => {
      dispatch(setProfileImageFile(uri));
    });
  };

  const handleRemoveImage = () => dispatch(clearProfileImage());

  return (
    <FormGroup>
      <Input
        type="file"
        name="imageUrl"
        onChange={handleImage}
        accept="image/*"
      />
      <div className="m-4 text-center">
        {uploadPreview ? (
          <div className="avatar">
            <span className="text-center">
              <TrashIcon size={30} color="red" onClick={handleRemoveImage} />
            </span>
            <img className="img-thumbnail" src={uploadPreview} alt="message" />
          </div>
        ) : (
          <div className="avatar">
            <img className="img-thumbnail" src={currentAvatar} alt="message" />
          </div>
        )}
      </div>
    </FormGroup>
  );
};

export default UploadProfileImage;
