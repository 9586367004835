import { gql } from "@apollo/client";

export const DEPOSIT_LIST = gql`
  query memberDeposits {
    memberDeposits {
      id
      profit
      amount
      returnDate
      createdAt
      status
    }
  }
`;
