import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { TRANSACTION_STATUS } from "../../constants/status";

const RenderStatus = () => {
  const { t } = useTranslation();

  return (
    <div className="d-flex">
      <span className="mr-3">{t("status")}:</span>
      <Badge pill bg={TRANSACTION_STATUS === "success" ? "success" : "warning"}>
        {t(TRANSACTION_STATUS === "success" ? "success" : "pending")}
      </Badge>
    </div>
  );
};

export default RenderStatus;
