import { Badge, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { TRANSACTION_STATUS } from "../../../constants/status";
import { simpleDate } from "../../../utils/formatDate";

const TransactionTable = ({ data }) => {
  const { t } = useTranslation();

  const renderStatus = (status) => {
    switch (status) {
      case TRANSACTION_STATUS.SUCCESS:
        return (
          <Badge bg="success">
            <span style={{ fontSize: "12px" }}>{t("success")}</span>
          </Badge>
        );
      case TRANSACTION_STATUS.PENDING:
        return (
          <Badge bg="warning">
            <span style={{ fontSize: "12px" }}> {t("pending")}</span>
          </Badge>
        );
      case TRANSACTION_STATUS.CANCEL:
        return (
          <Badge bg="danger">
            <span style={{ fontSize: "12px" }}>{t("unSuccess")}</span>
          </Badge>
        );
      default:
        break;
    }
  };

  return (
    <Table striped bordered hover variant="dark">
      <thead>
        <tr>
          <th>#</th>
          <th>{t("tNo")}</th>
          <th>{t("amount")}</th>
          <th>{t("status")}</th>
          <th>{t("date")}</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((t, i) => {
          return (
            <tr key={t.id}>
              <td>{i + 1}</td>
              <td>{t.transactionNo}</td>
              <td>{t.amount}</td>
              <td> {renderStatus(t.status)}</td>
              <td>{simpleDate(t.createdAt)}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default TransactionTable;
