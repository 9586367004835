import { Form, Button, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ROUTE } from "../../../constants/app_routes";

function RequireWithdrawPasswordModal({
  show,
  handleClose,
  handleConfirm,
  handleChange,
  loading,
  withdrawPassword,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <Modal.Body>
          <p className="text-center">{t("withdrawPassword")}</p>
          {withdrawPassword ? (
            <Form.Control type="password" onChange={handleChange} />
          ) : (
            <div className="d-flex">
              <p className="text-center text-sm text-danger m-1">
                {t("userNotSetWithdrawPassword")}
              </p>
              <Link to={ROUTE.WITHDRAW_PASSWORD}>
                <Button variant="info" className="text-white">
                  {t("updateWithdrawPassword")}
                </Button>
              </Link>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            className="me-2 btn-block btn-md"
            variant="danger"
            onClick={handleClose}
          >
            {t("cancel")}
          </Button>
          <Button
            className="me-2 btn-block btn-md"
            variant="primary"
            onClick={handleConfirm}
          >
            {loading ? <Spinner /> : t("confirm")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RequireWithdrawPasswordModal;
