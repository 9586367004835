import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { CameraColorIcon, VerifyIcon } from "../../constants/icon_path";
import { useProfileQuery } from "../../graphql/apollo/query-hooks";
import defaulAvatar from "../../assets/images/avatar.jpeg";
import { UPDATE_PROFILE_IMAGE } from "../../graphql/mutations/profile";
import { formatCurrency } from "../../utils/math";
import CustomModal from "../../components/bootstrap/CustomModal";
import UploadProfileImage from "./UploadProfileImage";
import UploadImageButton from "./UploadImageButton";
import IdentityForm from "../../pages/profile/components/IdentityForm";
import { toggleIdentityModel } from "../../pages/profile/redux/profileSlice";

const UserProfile = ({ showVerify = false }) => {
  const dispatch = useDispatch();
  const { isOpenIdentityModal } = useSelector((state) => state.profile);
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const { t } = useTranslation();

  const { memberProfile, refetch } = useProfileQuery();
  const [updateProfileImage] = useMutation(UPDATE_PROFILE_IMAGE, {
    onCompleted: () => {
      refetch();
    },
  });

  const handleUpload = () => setShowModal(true);

  const toggleIdentity = () => dispatch(toggleIdentityModel());

  const currentAvatar = memberProfile?.profileImage ?? defaulAvatar;
  return (
    <div className="user-profile">
      <CustomModal
        show={showModal}
        handleClose={handleCloseModal}
        size="md"
        title={
          <UploadImageButton
            handleClose={handleCloseModal}
            updateProfileImage={updateProfileImage}
          />
        }
      >
        <UploadProfileImage currentAvatar={currentAvatar} />
      </CustomModal>
      <CustomModal
        show={isOpenIdentityModal}
        handleClose={toggleIdentity}
        size="md"
        title={""}
      >
        <IdentityForm refetch={refetch} />
      </CustomModal>
      <Row>
        <Col md="2" sm="2" xs="2">
          <div className="user-profile__avatar">
            <img src={currentAvatar} alt="avatar" />
            <CameraColorIcon onClick={handleUpload} size={30} />
          </div>
        </Col>
        <Col md="10" sm="10" xs="10">
          <div className="user-profile__account">
            <p className="text-white">
              {t("account")}: {memberProfile?.nickname}(
              {memberProfile?.memberId})
            </p>
            <p className="text-white">
              {t("balance")}: {formatCurrency(memberProfile?.balance ?? 0)}{" "}
            </p>
          </div>
        </Col>
      </Row>
      {showVerify && (
        <Button
          variant={`outline-${
            memberProfile?.isVerified ? "success" : "danger"
          }`}
          className="verify"
          onClick={toggleIdentity}
        >
          <VerifyIcon size={25} />{" "}
          {t(
            memberProfile?.isPeningIdentity
              ? "peningIdentity"
              : memberProfile?.isVerified
              ? "identityIsVerified"
              : "pleaseVerifyIdentity"
          )}
        </Button>
      )}
    </div>
  );
};

export default UserProfile;
