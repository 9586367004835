import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useMutation } from "@apollo/client";

import BaseLayout from "../../components/layout";
import Header from "../../components/layout/headers/Header";
import DepositModal from "./components/DepositModal";
import { DEPOSIT } from "../../graphql/mutations/transaction";
import DepositTable from "./components/DepositTable";
import {
  showErrorToast,
  showSuccessToast,
} from "../../components/common/CustomErrorMessage";
import { useDepositsQuery } from "../../graphql/apollo/query-hooks";

const Deposit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showFirstModal, setShowFirstModal] = useState(false);
  const [data, setData] = useState({ profit: 0, amount: 0 });

  const toggleFirstModal = () => setShowFirstModal(!showFirstModal);

  const openModal = (profit) => {
    toggleFirstModal();
    setData({ ...data, profit });
  };

  const { memberDeposits, refetch } = useDepositsQuery();
  const [deposit, { loading }] = useMutation(DEPOSIT, {
    variables: { input: data },
    onCompleted: ({ deposit }) => {
      if (deposit) {
        showSuccessToast();
        setData({ profit: 0, amount: 0 });
        toggleFirstModal();
        refetch();
      }
    },
    onError: (error) => {
      showErrorToast(error.message);
    },
  });

  const handleChange = (e) => {
    setData({ ...data, amount: parseInt(e.target.value) });
  };

  const handleConfirm = async () => await deposit();

  return (
    <BaseLayout>
      <DepositModal
        show={showFirstModal}
        handleClose={toggleFirstModal}
        handleChange={handleChange}
        handleConfirm={handleConfirm}
        loading={loading}
      />
      <Header title={t("deposit")} onGoback={() => navigate(-1)} />
      <Container className="mt-2">
        <Card bg="secondary" className="p-2">
          <h5 className="text-white text-center">7วัน Financial Management</h5>
          <Row className="mt-3 mb-2">
            <Col xs="6">
              <h6 className="text-center text-white">{t("amount")}</h6>
              <h6 className="text-center text-white">3000~50000</h6>
            </Col>
            <Col xs="6">
              <h6 className="text-center text-white">รายได้</h6>
              <h6 className="text-center text-white">3.00%</h6>
            </Col>
          </Row>
          <Button onClick={() => openModal(0.03)} variant="info">
            ฝากตอนนี้
          </Button>
        </Card>
        <Card bg="secondary" className="p-2 mt-3">
          <h5 className="text-white text-center">7วัน Financial Management</h5>
          <Row className="mt-3 mb-2">
            <Col xs="6">
              <h6 className="text-center text-white">{t("amount")}</h6>
              <h6 className="text-center text-white">50000~100000+</h6>
            </Col>
            <Col xs="6">
              <h6 className="text-center text-white">รายได้</h6>
              <h6 className="text-center text-white">5.00%</h6>
            </Col>
          </Row>
          <Button onClick={() => openModal(0.05)} variant="info">
            ฝากตอนนี้
          </Button>
        </Card>
        <Card className="mt-3">
          <Card.Body>
            <h6>{t("depositList")}</h6>
            <hr />
            <DepositTable data={memberDeposits} />
          </Card.Body>
        </Card>
      </Container>
    </BaseLayout>
  );
};

export default Deposit;
