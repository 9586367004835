import { FormGroup, Input, Label } from "reactstrap";
import Resizer from "react-image-file-resizer";

import { ImageIcon, TrashIcon } from "../../constants/icon_path";

const CustomImagePicker = ({
  uploadPreview,
  handleRemoveImage,
  setImagePreview,
  setImageFile,
  label,
}) => {
  const handleImage = (e) => {
    const file = e.target.files[0];
    setImagePreview(window.URL.createObjectURL(file));
    Resizer.imageFileResizer(file, 720, 500, "JPEG", 100, 0, async (uri) => {
      setImageFile(uri);
    });
  };

  return (
    <FormGroup>
      <Label>{label}</Label>
      <Input
        type="file"
        name="imageUrl"
        onChange={handleImage}
        accept="image/*"
      />
      <div className="m-4 text-center">
        {uploadPreview ? (
          <div className="avatar">
            <span className="text-center">
              <TrashIcon size={30} color="red" onClick={handleRemoveImage} />
            </span>
            <img className="img-thumbnail" src={uploadPreview} alt="message" />
          </div>
        ) : (
          <div className="avatar">
            <ImageIcon size={120} />
          </div>
        )}
      </div>
    </FormGroup>
  );
};

export default CustomImagePicker;
