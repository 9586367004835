import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  uploadLoading: false,
  uploadError: null,
  file: "",
  uploadPreview: null,
  securityPassword: [],
  identityForm: {
    file: "",
    uploadPreview: null,
    cardName: "",
    cardNo: "",
  },
  isOpenIdentityModal: false,
};

export const uploadProfileImage = createAsyncThunk(
  "profile/upload",
  async ({ handleClose }, thunkAPI) => {
    const { file } = thunkAPI.getState().profile;
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_REST_ENDPOINT}/uploadProfileImage`,
        {
          file,
        }
      );
      handleClose();
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfileImageFile: (state, { payload }) => {
      state.file = payload;
    },
    setProfileImagePreview: (state, { payload }) => {
      state.uploadPreview = payload;
    },
    clearProfileImage: (state) => {
      state.file = initialState.file;
      state.uploadPreview = initialState.uploadPreview;
    },
    setSecurityPassword: (state, { payload }) => {
      if (state.securityPassword.length < 6) {
        state.securityPassword = [...state.securityPassword, payload];
      }
    },
    removeSecurityPassword: (state) => {
      if (state.securityPassword.length > 0) {
        const securityPassword = state.securityPassword;
        securityPassword.pop();
        state.securityPassword = securityPassword;
      }
    },
    handleChangeIdentityForm: (state, { payload }) => {
      state.identityForm = {
        ...state.identityForm,
        cardName: payload.cardName,
        cardNo: payload.cardNo,
      };
    },
    toggleIdentityModel: (state) => {
      state.isOpenIdentityModal = !state.isOpenIdentityModal;
    },
    setIdentityImageFile: (state, { payload }) => {
      state.identityForm.file = payload;
    },
    setIdentityImagePreview: (state, { payload }) => {
      state.identityForm.uploadPreview = payload;
    },
    clearIdentityImage: (state) => {
      state.identityForm.file = initialState.identityForm.file;
      state.identityForm.uploadPreview =
        initialState.identityForm.uploadPreview;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadProfileImage.pending, (state) => {
        state.uploadLoading = true;
      })
      .addCase(uploadProfileImage.fulfilled, (state) => {
        state.uploadLoading = false;
      })
      .addCase(uploadProfileImage.rejected, (state, action) => {
        state.uploadLoading = false;
        state.uploadError = action.payload;
      });
  },
});

export const {
  setProfileImageFile,
  setProfileImagePreview,
  clearProfileImage,
  setSecurityPassword,
  removeSecurityPassword,
  handleChangeIdentityForm,
  toggleIdentityModel,
  setIdentityImageFile,
  setIdentityImagePreview,
  clearIdentityImage,
} = profileSlice.actions;

export default profileSlice.reducer;
