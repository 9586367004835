import { useQuery } from "@apollo/client";

import {
  GET_BET_LIST,
  GET_COUNTRY_LIST,
  GET_CURRENT_GAME,
} from "../queries/game";
import { MEMBER_PROFILE } from "../queries/auth";
import { showErrorToast } from "../../components/common/CustomErrorMessage";
import { DEPOSIT_LIST } from "../queries/deposit";
import { INVESTMENT_PROFIT } from "../queries/profile";

export const useBetQuery = () => {
  const {
    loading,
    data: { getBetList } = {},
    error,
    refetch,
  } = useQuery(GET_BET_LIST);

  return { loadingBets: loading, getBetList, error, refetchBetList: refetch };
};

export const useProfileQuery = () => {
  const {
    loading,
    data: { memberProfile } = {},
    refetch,
  } = useQuery(MEMBER_PROFILE, {
    onError: (error) => showErrorToast(error.message),
  });

  return { loadingProfile: loading, memberProfile, refetch };
};

export const useCurrentGameQuery = () => {
  const {
    loading,
    data: { getCurrentGame } = {},
    refetch,
  } = useQuery(GET_CURRENT_GAME, {
    onError: (error) => showErrorToast(error.message),
  });

  return {
    loadingCurrentGame: loading,
    getCurrentGame,
    refetchCurrentGame: refetch,
  };
};

export const useDepositsQuery = () => {
  const {
    loading,
    data: { memberDeposits } = {},
    refetch,
  } = useQuery(DEPOSIT_LIST, {
    fetchPolicy: "network-only",
    onError: (error) => showErrorToast(error.message),
  });

  return {
    loading,
    memberDeposits,
    refetch,
  };
};

export const useCountryQuery = () => {
  const {
    loading,
    data: { getCountryList } = {},
    refetch,
  } = useQuery(GET_COUNTRY_LIST, {
    fetchPolicy: "cache-first",
    onError: (error) => showErrorToast(error.message),
  });

  return { loading, getCountryList, refetch };
};

export const useInvestmentProfitQuery = () => {
  const {
    loading,
    data: { memberInvestmentProfit } = {},
    refetch,
  } = useQuery(INVESTMENT_PROFIT, {
    fetchPolicy: "network-only",
    onError: (error) => showErrorToast(error.message),
  });

  return { loading, memberInvestmentProfit, refetch };
};
