import { Link, useLocation } from "react-router-dom";
import { Button, Container, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { simpleDate, simpleTime } from "../../utils/formatDate";
import { ROUTE } from "../../constants/app_routes";
import RenderStatus from "../../components/common/RenderStatus";
import BaseLayout from "../../components/layout";
import { formatCurrency } from "../../utils/math";

const TopupDetail = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { state } = location;

  return (
    <BaseLayout>
      <div className="topup">
        <Container className="mt-2">
          <ListGroup as="ul">
            <ListGroup.Item as="li" active>
              <p className="text-center ">{t("topupNotice")}</p>
            </ListGroup.Item>
            <ListGroup.Item as="li">
              {t("tNo")}: {state?.transactionNo}
            </ListGroup.Item>
            <ListGroup.Item as="li">
              {t("date")}: {simpleDate(state?.createdAt)}{" "}
              {simpleTime(state?.createdAt)}
            </ListGroup.Item>
            <ListGroup.Item as="li">
              {<RenderStatus status={state?.status} />}
            </ListGroup.Item>
            <ListGroup.Item as="li">
              {t("amount")}: {formatCurrency(state.amount ?? 0)} THB
            </ListGroup.Item>
            <ListGroup.Item as="li">
              {t("customerName")}: {state?.member?.nickname}
            </ListGroup.Item>
          </ListGroup>
          <div className="text-center">
            <Link to={ROUTE.HOME}>
              <Button>{t("back")}</Button>
            </Link>
          </div>
        </Container>
      </div>
    </BaseLayout>
  );
};

export default TopupDetail;
