import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import BaseLayout from "../../components/layout";
import Header from "../../components/layout/headers/Header";

const ProductDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <BaseLayout>
      <Header title={t("productDetail")} onGoback={() => navigate(-1)} />
      <div className="product-detail m-2">
        <Card>
          <Card.Body>
            <p>
              เรียนลูกค้า วิธีการเติมเงินจะได้รับการอัปเดตตามเวลาจริง
              โปรดปรึกษาฝ่ายบริการลูกค้าก่อนการเติมเงินแต่ละครั้งเพื่อรับบัญชีธนาคารล่าสุด
              หากโอนเงินผิดบัญชีหรือเติมเงินที่ไม่สอดคล้องกับวิธีการบริการลูกค้าจะส่งผลให้
              ถือเป็นโมฆะการสูญเสียจะตกเป็นภาระของบริษัทของเรา ขาดความรับผิดชอบ!
              ! เวลาให้บริการ 09:30-22:30 น. ทุกวัน ขอบคุณที่สนับสนุน ETF
              ขอให้ชีวิตมีความสุข!
            </p>
            <p>
              1. วิธีทำกิจกรรม(แพลตฟอร์ม) จะมีสามตัวที่นับรวมกัน (เช่น 3+5+2=10
              เรียกว่า เล็ก) ถ้ามากกว่า 10 เรียกว่า ใหญ่ ( 1-10 เล็ก) ( 11-18
              ใหญ่) 1,3,5,7,9,11, 13,15,17 (คี่); 2,4,6,8,10,12,14,16,18 (คู่)
            </p>
            <p>
              2. ผลการทำกิจกรรม (หลักพัน) ขึ้นอยู่กับจำนวนเงินที่ลูกค้าใช้ไป
            </p>
            <p>3. อัตราค่าเดินทางขึ้นอยู่กับระดับห้อง (เริ่มต้น, กลาง, VIP)</p>
          </Card.Body>
        </Card>
      </div>
    </BaseLayout>
  );
};

export default ProductDetail;
