import io from "socket.io-client";

const SocketClient = io.connect(process.env.REACT_APP_SOCKET_ENDPOINT, {
  transports: ["websocket", "polling"],
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: 3,
});
SocketClient.on("connect", () => {
  SocketClient.io.engine.on("upgrade", () => {});
});

SocketClient.on("connect_error", () => {});
export default SocketClient;
