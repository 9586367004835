import { Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  clearProfileImage,
  uploadProfileImage,
} from "../../pages/profile/redux/profileSlice";

const UploadImageButton = ({ handleClose, updateProfileImage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const { uploadLoading, file } = profile;

  const handleSend = async () => {
    const { payload } = await dispatch(uploadProfileImage({ handleClose }));
    if (payload.success) {
      dispatch(clearProfileImage());
      await updateProfileImage({
        variables: {
          imageUrl: payload.url,
        },
      });
    }
  };

  return (
    <Button
      type="button"
      onClick={handleSend}
      style={{ marginLeft: "5px" }}
      disabled={!file}
    >
      {uploadLoading ? (
        <Spinner variant="light" animation="border" size="sm" />
      ) : (
        t("update")
      )}
    </Button>
  );
};

export default UploadImageButton;
