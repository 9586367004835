import { gql } from "@apollo/client";
import { MEMBER_INFO } from "../fragments";

export const MEMBER_PROFILE = gql`
  query memberProfile {
    memberProfile {
      ...memberInfo
    }
  }
  ${MEMBER_INFO}
`;
