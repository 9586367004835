import { Offcanvas } from "react-bootstrap";
import { CrossIcon } from "../../constants/icon_path";

const CustomOffCanvas = ({
  show,
  handleClose,
  showHeader = true,
  height = "70%",
  backgroundColor = "white",
  backdrop = true,
  ...props
}) => {
  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        backdrop={backdrop}
        {...props}
        style={{ height: height, backgroundColor: backgroundColor }}
      >
        <Offcanvas.Header>
          {showHeader && (
            <Offcanvas.Title
              className="d-flex justify-content-between"
              style={{ width: "100%" }}
            >
              <CrossIcon color="grey" size={20} onClick={handleClose} />{" "}
              <span>{props.title}</span>
              <span></span>
            </Offcanvas.Title>
          )}
        </Offcanvas.Header>
        <Offcanvas.Body>{props.children}</Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CustomOffCanvas;
