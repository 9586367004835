import { useQuery } from "@apollo/client";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import TransactionTable from "./components/TransactionTable";
import { TOPUP_RECORD } from "../../graphql/queries/profile";
import BaseLayout from "../../components/layout";
import ProfileHeader from "./components/ProfileHeader";
import Header from "../../components/layout/headers/Header";

const TopupRecord = () => {
  const { t } = useTranslation();
  const { data } = useQuery(TOPUP_RECORD);
  return (
    <BaseLayout>
      <Header title={t("rechargeRecord")} />
      <Card className="profile__child p-1">
        <TransactionTable data={data?.topupRecord} />
      </Card>
    </BaseLayout>
  );
};

export default TopupRecord;
