import { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ROUTE } from "../../constants/app_routes";
import userIcon from "../../assets/images/user.png";
import logo from "../../assets/images/logo.jpeg";
import SwitchLanguage from "../../components/common/SwitchLanguage";
import Preloader from "../../components/common/Preloader";

const Landing = () => {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.isLogin) {
      navigate(ROUTE.LOGIN);
    } else {
      navigate(ROUTE.HOME);
    }
  }, [auth.isLogin, navigate]);
  return (
    <Suspense fallback={() => <Preloader />}>
      <div className="landing">
        <SwitchLanguage />
      </div>
      <div className="button-card">
        <Link to={ROUTE.LOGIN}>
          <div className="item">
            <img src={userIcon} alt="user" />
            <p>{t("login")}</p>
          </div>
        </Link>
      </div>
      <div className="icon">
        <img src={logo} alt="user" />
      </div>
    </Suspense>
  );
};

export default Landing;
