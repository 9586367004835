import { gql } from "@apollo/client";

export const BET_GAME = gql`
  mutation betGame($input: BetInput!) {
    betGame(input: $input) {
      id
      amount
      member {
        id
        memberId
        nickname
      }
      lottery {
        id
        lotteryNo
        status
        result
        createdAt
      }
    }
  }
`;
