import { gql } from "@apollo/client";
import { MESSAGE_INFO } from "../fragments";

export const GET_MESSAGES = gql`
  query getMessages($memberId: ID!) {
    getMessages(memberId: $memberId) {
      ...messageInfo
    }
  }
  ${MESSAGE_INFO}
`;

export const GET_MEMBER_CHAT_ROOM = gql`
  query getMemberChatRoom {
    getMemberChatRoom {
      id
      createdAt
      updatedAt
    }
  }
`;
