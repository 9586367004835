import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { useLocation } from "react-router-dom";

import HeaderAsset from "../../components/layout/headers/HeaderAsset";
import PeriodAccordion from "./components/PeriodAccordion";
import GameFooter from "./components/GameFooter";
import { BET_GAME } from "../../graphql/mutations/game";
import BetContainer from "./components/BetContainer";
import SocketClient from "../../SocketClient";
import { SOCKET_CLIENT } from "../../constants/socket";
import BaseLayout from "../../components/layout";
import InfoCard from "./components/InfoCard";
import { clearSelectOptions } from "./redux/gameSlice";
import {
  useBetQuery,
  useCurrentGameQuery,
  useProfileQuery,
} from "../../graphql/apollo/query-hooks";
import { showErrorToast } from "../../components/common/CustomErrorMessage";
import { currentBranch } from "../../constants/app_constants";

const Asset = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const game = useSelector((state) => state.game);

  const {
    memberProfile,
    refetch: refetchProfile,
    loadingProfile,
  } = useProfileQuery();
  const { getCurrentGame, refetchCurrentGame, loadingCurrent } =
    useCurrentGameQuery();
  const { getBetList, refetchBetList, loadingBets } = useBetQuery();

  const [betGame, { loading }] = useMutation(BET_GAME, {
    variables: {
      input: {
        amount: game.amount,
        options: game.betOptions,
        lottery: getCurrentGame?.id,
        room: state?.room.id,
        country: state?.country.id,
        branch: currentBranch,
      },
    },
    onCompleted: (data) => {
      if (data.betGame) {
        SocketClient.emit(SOCKET_CLIENT.GAME_BETED, data.betGame);
        dispatch(clearSelectOptions());
        refetchProfile();
      }
    },
    onError: (error) => {
      showErrorToast(error.message);
    },
  });

  useEffect(() => {
    SocketClient.on(SOCKET_CLIENT.RECIEVE_NEW_BET, () => {
      refetchBetList();
    });
  }, [refetchBetList]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await betGame();
  };

  return (
    <BaseLayout>
      <HeaderAsset />
      <div className="game">
        <InfoCard
          memberProfile={memberProfile}
          refetchCurrentGame={refetchCurrentGame}
          getCurrentGame={getCurrentGame}
          loadingMember={loadingProfile}
          loadingCurrent={loadingCurrent}
        />
        <PeriodAccordion />
        <BetContainer
          loadingBets={loadingBets}
          data={getBetList}
          currentUser={memberProfile}
        />
      </div>
      <GameFooter
        roomId={state?.room.id}
        handleSubmit={handleSubmit}
        loading={loading}
        betGame={betGame}
        memberProfile={memberProfile}
        refetchCurrentGame={refetchCurrentGame}
        getCurrentGame={getCurrentGame}
      />
    </BaseLayout>
  );
};

export default Asset;
