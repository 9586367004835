import { createSlice } from "@reduxjs/toolkit";
import { addBetOption } from "./utils";

const initialState = {
  betOptions: [],
  amount: 0,
  isFocusInput: false,
  isMobile: false,
  showRequireModal: false,
  showRequireMinimumModal: false,
  enterRoom: { password: "", room: "" },
};

const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    onSelectOption: (state, { payload }) => {
      state.betOptions = addBetOption(state.betOptions, payload);
    },
    onChangeBetAmount: (state, { payload }) => {
      state.amount = payload;
    },
    clearSelectOptions: (state) => {
      state.betOptions = initialState.betOptions;
      state.amount = initialState.amount;
      state.isMobile = initialState.isMobile;
      state.isFocusInput = initialState.isFocusInput;
    },
    setFocusInput: (state, { payload }) => {
      state.isFocusInput = payload;
    },
    toggleRequireModal: (state) => {
      state.showRequireModal = !state.showRequireModal;
    },
    toggleRequireMinimumModal: (state) => {
      state.showRequireMinimumModal = !state.showRequireMinimumModal;
    },
    setEnterRoom: (state, { payload }) => {
      state.enterRoom = {
        ...state.enterRoom,
        password: payload.password,
        room: payload.room,
      };
    },
  },
});

export const {
  onSelectOption,
  onChangeBetAmount,
  clearSelectOptions,
  setFocusInput,
  toggleRequireModal,
  setEnterRoom,
  toggleRequireMinimumModal,
} = gameSlice.actions;

export default gameSlice.reducer;
