import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Spinner } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import BaseLayout from "../../components/layout";
import Header from "../../components/layout/headers/Header";
import { security } from "../../constants/image_path";
import CustomErrorMessage, {
  showErrorToast,
  showSuccessToast,
} from "../../components/common/CustomErrorMessage";
import IconInput from "../../components/forms/IconInput";
import { FcLock } from "react-icons/fc";
import { UPDATE_LOGIN_PASSWORD } from "../../graphql/mutations/profile";
import { NewPasswordIcon } from "../../constants/icon_path";

const UpdatePassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [password, setPassword] = useState({
    oldPassword: "",
    password: "",
    passwordConfirm: "",
  });

  const handleChange = (e) => {
    setPassword({
      ...password,
      [e.target.name]: e.target.value,
    });
  };

  const [updatePassword, { loading, error }] = useMutation(
    UPDATE_LOGIN_PASSWORD,
    {
      variables: {
        input: password,
      },
      onCompleted: (data) => {
        if (data.updatePassword) {
          showSuccessToast();
          navigate(-1);
          setPassword({
            oldPassword: "",
            password: "",
            passwordConfirm: "",
          });
        }
      },
      onError: (err) => {
        showErrorToast(err.message);
      },
    }
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    await updatePassword();
  };

  const requireInput =
    !password.oldPassword || !password.password || !password.passwordConfirm;
  return (
    <BaseLayout>
      <Header title={t("changePassword")} />
      <div className="security">
        <img className="security-img mt-4" src={security} />
        <h1 className="text-center">{t("changePassword")}</h1>
        <div className="m-5">
          <Form onSubmit={handleSubmit}>
            <IconInput
              icon={<FcLock size={20} />}
              placeholder={t("pleaseenterOldPassword")}
              name="oldPassword"
              value={password.oldPassword}
              type="password"
              onChange={handleChange}
            />
            <IconInput
              icon={<FcLock size={20} />}
              placeholder={t("pleaseenterNewPassword")}
              name="password"
              value={password.password}
              type="password"
              onChange={handleChange}
            />
            <IconInput
              icon={<NewPasswordIcon size={20} />}
              placeholder={t("confirmNewPassword")}
              name="passwordConfirm"
              value={password.passwordConfirm}
              type="password"
              onChange={handleChange}
            />
            <p className="text-center">
              {error && <CustomErrorMessage messageKey={error.message} />}
            </p>
            <div className="d-grid gap-2 mb-2">
              <Button
                type="submit"
                variant="success"
                disabled={loading || requireInput}
              >
                {loading ? (
                  <Spinner size="md" animation="border" variant="light" />
                ) : null}{" "}
                {t("confirm")}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </BaseLayout>
  );
};

export default UpdatePassword;
