import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import BaseLayout from "../../components/layout";
import ChatInput from "./components/ChatInput";
import {
  CREATE_MESSAGE_NOTIFICATION,
  SEND_MESSAGE,
} from "../../graphql/mutations/message";
import SocketClient from "../../SocketClient";
import { SOCKET_CLIENT } from "../../constants/socket";
import ChatListContainer from "./components/ChatListContainer";
import {
  GET_MEMBER_CHAT_ROOM,
  GET_MESSAGES,
} from "../../graphql/queries/message";
import { clearMessage } from "./redux/messageSlice";
import { showErrorToast } from "../../components/common/CustomErrorMessage";
import ChatHeader from "./components/ChatHeader";
import { MEMBER_PROFILE } from "../../graphql/queries/auth";
import { currentBranch } from "../../constants/app_constants";

const Support = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { data: { getMemberChatRoom } = {} } = useQuery(GET_MEMBER_CHAT_ROOM);
  const [loadMessages, { data: { getMessages } = {}, refetch }] =
    useLazyQuery(GET_MESSAGES);
  const { data: { memberProfile } = {} } = useQuery(MEMBER_PROFILE, {
    onCompleted: ({ memberProfile }) => {
      if (memberProfile) {
        loadMessages({ variables: { memberId: memberProfile.id } });
      }
    },
  });
  const [createNotification] = useMutation(CREATE_MESSAGE_NOTIFICATION, {
    onCompleted: (data) => {
      if (data.createChatNotification) {
        SocketClient.emit(
          SOCKET_CLIENT.SEND_CHAT_NOTIFICATION,
          data.createChatNotification
        );
      }
    },
    onError: (error) => {
      showErrorToast(error.message);
    },
  });

  const [sendMessage] = useMutation(SEND_MESSAGE, {
    onCompleted: (data) => {
      if (data.sendMessage) {
        SocketClient.emit(SOCKET_CLIENT.SEND_MESSAGE, data.sendMessage);
        createNotification({
          variables: {
            input: {
              member: data.sendMessage.member.id,
              message: data.sendMessage.id,
              toAdmin: true,
              branch: currentBranch,
            },
          },
        });
        dispatch(clearMessage());
        refetch();
      }
    },
    onError: (error) => {
      showErrorToast(error.message);
    },
  });

  useEffect(() => {
    SocketClient.on(SOCKET_CLIENT.RECIEVE_MESSAGE, (data) => {
      if (data.chatRoom.id === getMemberChatRoom?.id) {
        refetch();
      }
    });
  }, [getMemberChatRoom?.id, refetch]);

  return (
    <BaseLayout>
      <ChatHeader title={t("customerService")} />
      <div className="support">
        <ChatListContainer data={getMessages} />
      </div>
      <ChatInput sendMessage={sendMessage} memberProfile={memberProfile} />
    </BaseLayout>
  );
};

export default Support;
