import { useState } from "react";
import { useMutation } from "@apollo/client";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Footer from "../../components/layout/footers/Footer";
import { ROUTE } from "../../constants/app_routes";
import BaseLayout from "../../components/layout";
import { UPDATE_PROFILE_IMAGE } from "../../graphql/mutations/profile";
import CustomModal from "../../components/bootstrap/CustomModal";
import defaulAvatar from "../../assets/images/avatar.jpeg";
import { formatCurrency } from "../../utils/math";
import {
  CardIcon,
  ListIcon,
  LogoutIcon,
  PasswordIcon,
  SecurityIcon,
  SettingIcon,
  TransactionIcon,
  VipIcon,
  WalletIcon,
  WithdrawIcon,
} from "../../constants/icon_path";
import { GiWallet } from "react-icons/gi";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import {
  useInvestmentProfitQuery,
  useProfileQuery,
} from "../../graphql/apollo/query-hooks";
import UploadImageButton from "../../components/shared/UploadImageButton";
import UploadProfileImage from "../../components/shared/UploadProfileImage";
import UserProfile from "../../components/shared/UserProfile";
import { gfpt } from "../../constants/image_path";
import ProfileMenuItem from "./components/ProfileMenuItem";
import { logout } from "../auth/redux/authSlice";

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const { t } = useTranslation();

  const { memberProfile, refetch } = useProfileQuery();

  const [updateProfileImage] = useMutation(UPDATE_PROFILE_IMAGE, {
    onCompleted: () => {
      refetch();
    },
  });

  const { memberInvestmentProfit } = useInvestmentProfitQuery();

  const currentAvatar = memberProfile?.profileImage ?? defaulAvatar;

  const handleLogout = () => dispatch(logout({ navigate }));

  return (
    <BaseLayout>
      <CustomModal
        show={showModal}
        handleClose={handleCloseModal}
        size="md"
        title={
          <UploadImageButton
            handleClose={handleCloseModal}
            updateProfileImage={updateProfileImage}
          />
        }
      >
        <UploadProfileImage currentAvatar={currentAvatar} />
      </CustomModal>
      <div className="profile">
        <div className="profile__header">
          <UserProfile showVerify={true} />
          <div className="profile__header_balance mt-2">
            <div className="profile__header_balance-asset">
              <MdOutlineAccountBalanceWallet size={20} color="#982229" />
              <span>ทรัพย์สินของฉัน</span>
            </div>
            <div className="profile__header_balance-vip">
              <VipIcon color="white" size={20} />
              <span className="text-white">ดูสิทธิพิเศษวีไอพี</span>
            </div>
            <Row className="mt-4">
              <Col sm="6" xs="6">
                <h3 className="text-center">
                  {formatCurrency(memberProfile?.balance ?? 0)}{" "}
                </h3>
                <h6 className="text-center">
                  <MdOutlineAccountBalanceWallet size={15} color="#982229" />
                  {t("totalAssets")} (THB)
                </h6>
              </Col>
              <Col sm="6" xs="6">
                <h3 className="text-center">
                  {formatCurrency(memberInvestmentProfit?.profit ?? 0)}
                </h3>
                <h6 className="text-center">{t("todayProfit")} (THB)</h6>
              </Col>
            </Row>
          </div>
        </div>
        <div className="profile__header_buttons">
          <div className="button">
            <Link to={ROUTE.TOPUP}>
              <GiWallet size={30} color="#982229" />{" "}
            </Link>
            <p className="text-center mt-2">{t("recharge")}</p>
          </div>
          <div className="button">
            <Link to={ROUTE.WITHDRAW}>
              <WithdrawIcon size={30} color="#982229" />
            </Link>
            <p className="text-center mt-2">{t("withdraw")}</p>
          </div>
          <div className="button">
            <Link to={ROUTE.TRANSACTION_RECORD}>
              <TransactionIcon size={30} color="#982229" />
            </Link>
            <p className="text-center mt-2">{t("บันทึกการทำธุรกรรม")}</p>
          </div>
          <div className="button">
            <Link to={ROUTE.CONNECT_ACCOUNT}>
              <CardIcon size={30} color="#982229" />
            </Link>
            <p className="text-center mt-2">{t("connectBankAccount")}</p>
          </div>
        </div>
        <img src={gfpt} alt="jp" />
        <div className="profile__menu">
          <Card>
            <ProfileMenuItem
              to={ROUTE.WITHDRAW_RECORD}
              title={t("รายละเอียดเงินทุน")}
              MenuIcon={WalletIcon}
            />
            <ProfileMenuItem
              to={ROUTE.BET_RECORD}
              title={t("betRecord")}
              MenuIcon={ListIcon}
            />
            <ProfileMenuItem
              to={ROUTE.SECURITY}
              title={t("setSecurityPassword")}
              MenuIcon={SecurityIcon}
            />
            <ProfileMenuItem
              to={ROUTE.WITHDRAW_PASSWORD}
              title={t("withdrawPassword")}
              MenuIcon={PasswordIcon}
            />
            <ProfileMenuItem
              to={ROUTE.SETTING}
              title={t("การตั้งค่าระบบ")}
              MenuIcon={SettingIcon}
              showHr={false}
            />
          </Card>
          <div className="d-grid gap-2 pt-3">
            <Button variant="danger" onClick={handleLogout}>
              <LogoutIcon size={20} /> {t("logout")}
            </Button>
          </div>
        </div>
        <Footer />
      </div>
    </BaseLayout>
  );
};

export default Profile;
