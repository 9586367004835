import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import { useQuery } from "@apollo/client";

import BaseLayout from "../../components/layout";
import Header from "../../components/layout/headers/Header";
import { INVESTMENT_RECORD } from "../../graphql/queries/profile";
import BetRecordTable from "./components/BetRecordTable";

const BetRecord = () => {
  const { t } = useTranslation();
  const { data: { betRecord } = {} } = useQuery(INVESTMENT_RECORD);

  return (
    <BaseLayout>
      <Header title={t("betRecord")} />
      <div className="profile__child p-2">
        <Card className="p-0">
          <BetRecordTable data={betRecord} />
        </Card>
      </div>
    </BaseLayout>
  );
};

export default BetRecord;
