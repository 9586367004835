import jwtDecode from "jwt-decode";
import axios from "axios";

import { handleLoginSuccess, logout } from "../pages/auth/redux/authSlice";
import { STORAGE_KEY, getToken, removeToken, saveToken } from "./LocalStorage";

export function saveTokenInLocalStorage(tokens) {
  saveToken(STORAGE_KEY.ACCESS, tokens.accessToken);
  if (tokens?.refreshToken) {
    saveToken(STORAGE_KEY.REFRESH, tokens.refreshToken);
  }
}

export function removeTokenFromLocalStorage() {
  removeToken(STORAGE_KEY.ACCESS);
  removeToken(STORAGE_KEY.REFRESH);
}

export async function renewToken(refreshToken, dispatch, navigate) {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_REST_ENDPOINT}/renewToken`,
      {
        token: refreshToken,
      }
    );
    if (res.status == 403) {
      removeTokenFromLocalStorage();
      dispatch(logout({ navigate }));
      return;
    }
    return res.data;
  } catch (error) {
    dispatch(logout({ navigate }));
  }
}

export function decodeToken(token) {
  if (token) {
    const now = Date.now();
    const decoded = jwtDecode(token);
    if (decoded.exp * 1000 < now) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

export async function checkAutoLogin(dispatch, navigate) {
  const accessToken = getToken(STORAGE_KEY.ACCESS);

  const decodedAccess = decodeToken(accessToken);
  if (!decodedAccess) {
    const refreshToken = getToken(STORAGE_KEY.REFRESH);
    if (!refreshToken) {
      removeTokenFromLocalStorage();
      dispatch(logout({ navigate }));
      return;
    }
    const decodedRefresh = decodeToken(refreshToken);
    if (!decodedRefresh) {
      removeTokenFromLocalStorage();
      dispatch(logout({ navigate }));
      return;
    }

    const newToken = await renewToken(refreshToken, dispatch, navigate);
    if (newToken.success) {
      const tokens = newToken.tokens;
      saveTokenInLocalStorage(tokens);
      dispatch(handleLoginSuccess());
    }
  }
  dispatch(handleLoginSuccess());
}
