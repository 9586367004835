import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { MEMBER_PROFILE } from "../../../graphql/queries/auth";

import SwitchLanguage from "../../common/SwitchLanguage";
import { formatCurrency } from "../../../utils/math";

const HeaderHome = () => {
  const { t } = useTranslation();
  const { data: { memberProfile } = {} } = useQuery(MEMBER_PROFILE, {
    fetchPolicy: "network-only",
  });

  return (
    <div className="header">
      <div className="d-flex justify-content-between align-items-center">
        <p className="assets">
          {t("totalAssets")}: {formatCurrency(memberProfile?.balance ?? 0)} THB
        </p>
        <SwitchLanguage showText={false} />
      </div>
    </div>
  );
};

export default HeaderHome;
