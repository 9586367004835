import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CurrentGameCard from "./CurrentGameCard";
import InfoSkeleton from "./sketleton/InfoSkeleton";
import { formatCurrency } from "../../../utils/math";

const InfoCard = ({
  memberProfile,
  getCurrentGame,
  refetchCurrentGame,
  loadingMember,
  loadingCurrent,
}) => {
  const { t } = useTranslation();
  return (
    <Card className="p-1 info-card">
      <Row>
        <Col xs="5">
          {getCurrentGame ? (
            <CurrentGameCard
              currentGame={getCurrentGame}
              refetch={refetchCurrentGame}
              loadingCurrent={loadingCurrent}
            />
          ) : null}
        </Col>
        <Col xs="7">
          {loadingMember ? (
            <InfoSkeleton />
          ) : (
            <div className="asset-profile m-2">
              <div className="user-detail">
                <h4>{memberProfile?.nickname}</h4>
                <p>
                  {t("totalAssets")}:{" "}
                  {formatCurrency(memberProfile?.balance ?? 0)} THB
                </p>
                <p>
                  {t("ID")}: {memberProfile?.memberId}
                </p>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default InfoCard;
