import { useState } from "react";
import { useQuery } from "@apollo/client";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  GET_BET_OPTIONS,
  IS_ROOM_BUG_OPEN,
} from "../../../graphql/queries/game";
import { onSelectOption } from "../redux/gameSlice";
import { currentBranch } from "../../../constants/app_constants";

const BetOptionButtons = ({ roomId }) => {
  const dispatch = useDispatch();
  const gameState = useSelector((state) => state.game);
  const [options, setOptions] = useState([]);
  const { betOptions } = gameState;
  const { data: { betOptionList } = {} } = useQuery(GET_BET_OPTIONS, {
    variables: { branch: currentBranch },
    onCompleted: ({ betOptionList }) => {
      if (betOptionList.length > 0) {
        setOptions(betOptionList[0].options);
      }
    },
  });
  const { i18n } = useTranslation();
  const lng = i18n.language;

  const handleSelect = (betOption) => dispatch(onSelectOption(betOption));
  const bigOptions = options?.filter(
    (option) => option.engName === "big" || option.engName === "small"
  );
  const smallOptions = options?.filter(
    (option) => option.engName !== "big" && option.engName !== "small"
  );

  const { data: { isRoomBugOpen } = {} } = useQuery(IS_ROOM_BUG_OPEN, {
    variables: { id: roomId },
  });

  return (
    <Container>
      <div className="button-container">
        {betOptionList && (
          <Nav fill variant="underline" defaultActiveKey={betOptionList[0].id}>
            {betOptionList?.map((option) => {
              return (
                <Nav.Item
                  key={option.id}
                  onClick={() => setOptions(option.options)}
                >
                  <Nav.Link eventKey={option.id}>{option.name}</Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>
        )}
        <Row className="m-2">
          {smallOptions &&
            smallOptions.map((option) => {
              const isSelected = betOptions.includes(option.id);
              return (
                <Col xs={3} md={3} key={option.id}>
                  <button
                    className={`custom_btn ${
                      isSelected ? "selected" : "unselected"
                    }`}
                    onClick={() => handleSelect(option.id)}
                  >
                    <h6>{lng === "th" ? option.name : option.engName}</h6>
                    <h6>{option.profit}</h6>
                  </button>
                </Col>
              );
            })}
        </Row>
        <Row className="m-2">
          {bigOptions &&
            bigOptions.map((option) => {
              const isSelected = betOptions.includes(option.id);
              return (
                <Col xs={6} md={6} key={option.id}>
                  <button
                    className={`custom_btn big ${
                      isSelected ? "selected" : "unselected"
                    }`}
                    onClick={() => handleSelect(option.id)}
                  >
                    <h6>{lng === "th" ? option.name : option.engName}</h6>
                    <h6>{isRoomBugOpen ? "2.07" : option.profit}</h6>
                  </button>
                </Col>
              );
            })}
        </Row>
      </div>
    </Container>
  );
};

export default BetOptionButtons;
