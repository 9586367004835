import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import { FcPhoneAndroid, FcLock } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import loginIcon from "../../assets/images/login-icon.png";
import IconInput from "../../components/forms/IconInput";
import { ROUTE } from "../../constants/app_routes";
import { LOGIN } from "../../graphql/mutations/auth";
import { handleLoginSuccess } from "./redux/authSlice";
import {
  checkAutoLogin,
  saveTokenInLocalStorage,
} from "../../services/AuthService";
import CustomErrorMessage, {
  showErrorToast,
  showSuccessToast,
} from "../../components/common/CustomErrorMessage";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState({ username: "", password: "" });
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.isLogin) navigate(ROUTE.HOME);
  }, [auth.isLogin, navigate]);

  useEffect(() => {
    checkAutoLogin(dispatch, navigate);
  }, [dispatch, navigate]);

  const onChange = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const [handleLogin, { loading, error }] = useMutation(LOGIN, {
    variables: { username: userInfo.username, password: userInfo.password },
    onCompleted: (data) => {
      if (data && data.loginMember) {
        saveTokenInLocalStorage(data.loginMember.tokens);
        dispatch(handleLoginSuccess());
        showSuccessToast();
        navigate(ROUTE.HOME);
      }
    },
    onError(error) {
      showErrorToast(error.message);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await handleLogin();
  };

  const requireInput = !userInfo.username || !userInfo.password;

  return (
    <div className="login">
      <div className="image">
        <img src={loginIcon} alt="user" className="img" />
        <Container>
          <Form onSubmit={handleSubmit}>
            <IconInput
              icon={<FcPhoneAndroid size={20} />}
              placeholder={t("pleaseEnterAccountNo")}
              name="username"
              value={userInfo.username}
              type="text"
              onChange={onChange}
            />
            <IconInput
              icon={<FcLock size={20} />}
              placeholder={t("pleaseEnterPassword")}
              name="password"
              value={userInfo.password}
              type="password"
              onChange={onChange}
            />
            <p className="text-center">
              {error && <CustomErrorMessage messageKey={error.message} />}
            </p>
            <div className="d-grid gap-2 mb-2">
              <Button
                type="submit"
                variant="success"
                disabled={loading || requireInput}
              >
                {loading ? (
                  <Spinner size="md" animation="border" variant="light" />
                ) : null}{" "}
                {t("confirm")}
              </Button>
            </div>
            <Link to={ROUTE.REGISTER}>
              <p className="text-center">{t("register")}</p>
            </Link>
          </Form>
        </Container>
      </div>
    </div>
  );
};

export default Login;
