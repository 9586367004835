import { FormGroup, Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Resizer from "react-image-file-resizer";

import {
  removeMessageImage,
  setMessageImageFile,
  setMessageImagePreview,
} from "../redux/messageSlice";
import { TrashIcon } from "../../../constants/icon_path";

const UploadMessageImage = () => {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.message);
  const { uploadPreview } = message;

  const handleImage = (e) => {
    const file = e.target.files[0];
    dispatch(setMessageImagePreview(window.URL.createObjectURL(file)));
    Resizer.imageFileResizer(file, 720, 500, "JPEG", 100, 0, async (uri) => {
      dispatch(setMessageImageFile(uri));
    });
  };

  const handleRemoveImage = () => dispatch(removeMessageImage());

  return (
    <FormGroup>
      <Input
        type="file"
        name="imageUrl"
        onChange={handleImage}
        accept="image/*"
      />
      {uploadPreview && (
        <div className="m-2">
          <span className="text-center">
            <TrashIcon size={30} color="red" onClick={handleRemoveImage} />
          </span>

          <img className="img-thumbnail" src={uploadPreview} alt="message " />
        </div>
      )}
    </FormGroup>
  );
};

export default UploadMessageImage;
