import { useTranslation } from "react-i18next";

import HomeMenuItem from "./HomeMenuItem";
import {
  CustomerSupportIcon,
  DetailIcon,
  GiftIcon,
} from "../../../constants/icon_path";
import { ROUTE } from "../../../constants/app_routes";
import { GiWallet } from "react-icons/gi";

const HomeMenu = () => {
  const { t } = useTranslation();
  return (
    <div className="home__menu">
      <HomeMenuItem
        title={t("deposit")}
        icon={<GiftIcon size={30} />}
        to={ROUTE.DEPOSIT}
      />
      <HomeMenuItem
        title={t("recharge")}
        icon={<GiWallet size={30} />}
        to={ROUTE.TOPUP}
      />
      <HomeMenuItem
        title={t("productDetail")}
        icon={<DetailIcon size={30} />}
        to={ROUTE.PRODUCT_DETAIL}
      />
      <HomeMenuItem
        title={t("onlineService")}
        icon={<CustomerSupportIcon size={30} />}
        to={ROUTE.SUPPORT}
      />
    </div>
  );
};

export default HomeMenu;
