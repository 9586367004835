import { gql } from "@apollo/client";
import { MESSAGE_INFO } from "../fragments";

export const SEND_MESSAGE = gql`
  mutation sendMessage($input: MessageInput!) {
    sendMessage(input: $input) {
      ...messageInfo
    }
  }
  ${MESSAGE_INFO}
`;

export const CREATE_MESSAGE_NOTIFICATION = gql`
  mutation createChatNotification($input: ChatNotificationInput!) {
    createChatNotification(input: $input) {
      id
      isRead
      branch {
        id
        name
      }
    }
  }
`;
