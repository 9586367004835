import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Container, Form } from "react-bootstrap";

import { MEMBER_PROFILE } from "../../graphql/queries/auth";
import BaseLayout from "../../components/layout";
import {
  showErrorToast,
  showSuccessToast,
} from "../../components/common/CustomErrorMessage";
import Header from "../../components/layout/headers/Header";
import SubmitButton from "../../components/common/SubmitButton";
import { UPDATE_WITHDRAW_PASSWORD } from "../../graphql/mutations/profile";
import IconInput from "../../components/forms/IconInput";
import { FcLock } from "react-icons/fc";

const WithdrawPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [password, setPassword] = useState({
    oldPassword: "",
    password: "",
    passwordConfirm: "",
  });

  const { data: { memberProfile } = {}, refetch } = useQuery(MEMBER_PROFILE, {
    fetchPolicy: "network-only",
  });

  const havePassword = memberProfile?.withdrawPassword;

  const handleChange = (e) => {
    setPassword({
      ...password,
      [e.target.name]: e.target.value,
    });
  };

  const [updateWithdrawPassword, { loading }] = useMutation(
    UPDATE_WITHDRAW_PASSWORD,
    {
      variables: {
        input: password,
      },
      onCompleted: (data) => {
        if (data.updateWithdrawPassword) {
          showSuccessToast();
          refetch();
          navigate(-1);
          setPassword({
            oldPassword: "",
            password: "",
            passwordConfirm: "",
          });
        }
      },
      onError: (err) => {
        showErrorToast(err.message);
      },
    }
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (havePassword && password.oldPassword === "") {
      toast.error(t("pleaseenterOldPassword"), {
        position: "top-center",
      });
      return;
    } else if (password.password === "") {
      toast.error(t("pleaseenterNewPassword"), {
        position: "top-center",
      });
      return;
    } else if (password.passwordConfirm === "") {
      toast.error(t("pleaseConfirmPassword"), {
        position: "top-center",
      });
      return;
    } else if (password.passwordConfirm != password.password) {
      toast.error(t("passwordNotMatch"), {
        position: "top-center",
      });
      return;
    }

    await updateWithdrawPassword();
  };

  return (
    <BaseLayout>
      <Header
        title={t("updateWithdrawPassword")}
        onGoback={() => navigate(-1)}
      />
      <Container className="mt-3">
        <Form onSubmit={handleSubmit}>
          {havePassword && (
            <IconInput
              icon={<FcLock size={20} />}
              placeholder={t("pleaseenterOldPassword")}
              name="oldPassword"
              value={password.oldPassword}
              type="password"
              onChange={handleChange}
            />
          )}
          <IconInput
            icon={<FcLock size={20} />}
            placeholder={t("newPassword")}
            name="password"
            value={password.password}
            type="password"
            onChange={handleChange}
          />
          <IconInput
            icon={<FcLock size={20} />}
            placeholder={t("confirmNewPassword")}
            name="passwordConfirm"
            value={password.passwordConfirm}
            type="password"
            onChange={handleChange}
          />
          <div className="d-grid gap-2">
            <SubmitButton
              disabled={loading}
              text={t("confirm")}
              loading={loading}
            />
          </div>
        </Form>
      </Container>
    </BaseLayout>
  );
};

export default WithdrawPassword;
