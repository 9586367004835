import { configureStore } from "@reduxjs/toolkit";

import authSlice from "../pages/auth/redux/authSlice";
import gameSlice from "../pages/asset/redux/gameSlice";
import messageSlice from "../pages/support/redux/messageSlice";
import profileSlice from "../pages/profile/redux/profileSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    game: gameSlice,
    message: messageSlice,
    profile: profileSlice,
  },
});

export default store;
