import { gql } from "@apollo/client";

export const TRANSACTION_INFO = gql`
  fragment transactionInfo on Transaction {
    id
    member {
      id
      nickname
      memberId
    }
    transactionNo
    status
    amount
    action
    createdAt
  }
`;

export const TRANSACTION_RECORD_INFO = gql`
  fragment transactionRecordInfo on Transaction {
    id
    transactionNo
    status
    amount
    action
    createdAt
  }
`;

export const MEMBER_INFO = gql`
  fragment memberInfo on Member {
    id
    username
    nickname
    balance
    memberLevel
    withdrawPassword
    account {
      accountName
      accountNo
      bankName
    }
    memberId
    profileImage
    updatedAccount
    identity {
      cardName
      cardNo
      cardImageUrl
    }
    isVerified
    isPeningIdentity
    accountType
    crypto {
      address
      network
    }
  }
`;

export const GAME_INFO = gql`
  fragment gameInfo on Lottery {
    id
    lotteryNo
    result
    status
    createdAt
  }
`;

export const BET_INFO = gql`
  fragment betInfo on Bet {
    id
    amount
    createdAt
    lottery {
      id
      lotteryNo
    }
    options {
      id
      name
      engName
    }
    member {
      id
      memberId
      nickname
    }
  }
`;

export const MESSAGE_INFO = gql`
  fragment messageInfo on Message {
    id
    isAdmin
    chatRoom {
      id
    }
    member {
      id
      memberId
      nickname
    }
    text {
      message
      imageUrl
      isImage
    }
    createdAt
  }
`;
