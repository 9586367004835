import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import { ROUTE } from "../../../constants/app_routes";
import { ChevronBackIcon } from "../../../constants/icon_path";

const ChatHeader = () => {
  const { t } = useTranslation();

  return (
    <div className="chat_header">
      <div className="background-layer">
        <div className="content">
          <Link to={ROUTE.HOME}>
            <ChevronBackIcon size={30} color="white" />{" "}
          </Link>
          <span className="text-white">{t("customerService")}</span>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default ChatHeader;
