import { Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import SubmitButton from "../../../components/common/SubmitButton";
import BetOptionButtons from "./BetOptionButtons";
import { onChangeBetAmount, setFocusInput } from "../redux/gameSlice";

const GameFooter = ({ loading, handleSubmit, roomId }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const game = useSelector((state) => state.game);
  const { amount } = game;

  const handleChange = (e) =>
    dispatch(onChangeBetAmount(parseInt(e.target.value)));

  const handleFocus = (status) => {
    dispatch(setFocusInput(status));
  };

  return (
    <Container fluid>
      <div className="game__footer">
        <BetOptionButtons roomId={roomId} />
        <Row className="m-1 mt-3">
          <Col xs={3} md="2">
            <p className="text-center text-light">{t("buyPoints")}</p>
          </Col>
          <Col xs={6} md="8">
            <Form.Control
              value={amount}
              type="number"
              onChange={handleChange}
              onFocus={() => handleFocus(true)}
              onBlur={() => handleFocus(false)}
            />
          </Col>
          <Col xs="3" md="2">
            <Form onSubmit={handleSubmit}>
              <SubmitButton
                loading={loading}
                disabled={loading}
                text={t("buy")}
                isBlockButton={false}
                variant="primary"
              />
            </Form>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default GameFooter;
