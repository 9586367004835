import { Form, Button, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

function RequireModal({
  show,
  handleClose,
  handleConfirm,
  handleChange,
  loading,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <Modal.Body>
          <p className="text-center">{t("pleaseEnterPassword")}</p>
          <Form.Control type="password" onChange={handleChange} />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            className="me-2 btn-block btn-md"
            variant="danger"
            onClick={handleClose}
          >
            {t("cancel")}
          </Button>
          <Button
            className="me-2 btn-block btn-md"
            variant="primary"
            onClick={handleConfirm}
          >
            {loading ? <Spinner /> : t("confirm")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RequireModal;
