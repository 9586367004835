import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import { useQuery } from "@apollo/client";

import BaseLayout from "../../components/layout";
import Header from "../../components/layout/headers/Header";
import { TOPUP_RECORD } from "../../graphql/queries/profile";
import TransactionTable from "./components/TransactionTable";
import FilterTransactionDropdown from "./components/FilterTransactionDropdown";

const TransactionRecord = () => {
  const { t } = useTranslation();
  const { data } = useQuery(TOPUP_RECORD);
  return (
    <BaseLayout>
      <Header title={t("บันทึกการทำธุรกรรม")} />
      <div className="profile__child p-2">
        <div className="profile__child-filter mt-3 mb-3 d-flex justify-content-between">
          <FilterTransactionDropdown title={"ทุกประเภท"} />
        </div>
        <Card className="p-0">
          <TransactionTable data={data?.topupRecord} />
        </Card>
      </div>
    </BaseLayout>
  );
};

export default TransactionRecord;
