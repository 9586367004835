import { Placeholder, Card } from "react-bootstrap";

const InfoSkeleton = () => {
  return (
    <Placeholder as={Card.Text} animation="glow">
      <Placeholder md={11} size="sm" />
      <Placeholder xs={11} size="sm" />
      <Placeholder xs={11} size="sm" />
    </Placeholder>
  );
};

export default InfoSkeleton;
