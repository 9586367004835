import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { simpleDate } from "../../../utils/formatDate";

const BetRecordTable = ({ data }) => {
  const { t, i18n } = useTranslation();
  const th = i18n.language == "th";

  const renderStatus = (amount, updatedAmount) => {
    if (updatedAmount > amount) {
      return <span className="text-success">{t("investSuccess")}</span>;
    } else {
      return <span className="text-danger">{t("investFail")}</span>;
    }
  };

  const renderAmount = (amount, updatedAmount, difference) => {
    if (updatedAmount > amount) {
      return <span className="text-success">+ {difference}</span>;
    } else {
      return <span className="text-danger">- {difference}</span>;
    }
  };

  const renderBetOptions = (options) => {
    return options?.map((option) => (
      <span key={option.id}>{th ? option.name : option.engName}</span>
    ));
  };

  return (
    <Table
      striped
      bordered
      hover
      variant="dark"
      className="bet-table"
      size="sm"
      responsive
    >
      <thead>
        <tr>
          <th>#</th>
          <th>{t("rounds")}</th>
          <th>{t("amount")}</th>
          <th>{t("profitAmount")}</th>
          <th>{t("status")}</th>
          <th>{t("date")}</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((t, i) => {
          return (
            <tr key={t.id}>
              <td>{i + 1}</td>
              <td>
                {t.lottery.lotteryNo}/ {renderBetOptions(t.options)}
              </td>
              <td>{t.amount}</td>
              <td>
                {renderAmount(
                  t.amount,
                  t?.updatedAmount ?? 0,
                  t?.difference ?? 0
                )}
              </td>
              <td>{renderStatus(t.amount, t?.updatedAmount ?? 0)}</td>
              <td>{simpleDate(t.createdAt)}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default BetRecordTable;
