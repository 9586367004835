import { Card } from "react-bootstrap";

import { simpleTime, simpleDate } from "../../../utils/formatDate";
import CustomerService from "../../../assets/images/customer-service.png";

const ChatItem = ({ message }) => {
  const myMsg = message.isAdmin ? false : true;
  const isImage = message.text.isImage ? true : false;

  const renderTime = (date) => {
    const today = new Date();
    const todayDate = simpleDate(today);
    const msgDate = simpleDate(date);
    if (todayDate === msgDate) {
      return <span>{simpleTime(date)}</span>;
    } else {
      return (
        <span>
          {simpleDate(date)} {simpleTime(date)}
        </span>
      );
    }
  };

  return (
    <div className={`chat_item`}>
      {myMsg ? (
        <div className="my_msg">
          <div className="text-body">
            <Card bg={!isImage ? "success" : ""} className="my-card">
              <div className={`px-2 pt-2`}>
                {message.text.isImage ? (
                  <div className="d-flex flex-column">
                    <img
                      src={message.text.imageUrl}
                      className="img-thumbnail"
                      alt="msg"
                    />
                    <span className={`${"text-dark"} m-1`}>
                      {renderTime(message.createdAt)}
                    </span>
                  </div>
                ) : (
                  <>
                    <p className={"text-white"}>{message.text?.message}</p>
                    <span className={`p-2 text-white`}>
                      {renderTime(message.createdAt)}
                    </span>
                  </>
                )}
              </div>
            </Card>
          </div>
        </div>
      ) : (
        <div className="admin">
          <div className="text-body d-flex">
            <Card className="admin-card">
              <div className="d-flex">
                <div className="avatar p-2 ">
                  <img src={CustomerService} alt="avatar" />
                </div>
                <div className={`px-2 pt-2`}>
                  {message.text.isImage ? (
                    <>
                      <div className="m-2">
                        <img
                          src={message.text.imageUrl}
                          className="img-thumbnail"
                          alt="msg"
                        />
                      </div>
                      <span className={`${"text-dark"}`}>
                        {renderTime(message.createdAt)}
                      </span>
                    </>
                  ) : (
                    <>
                      <p className={"text-dark"}>{message.text?.message}</p>
                      <span className={`p-2 text-dark`}>
                        {renderTime(message.createdAt)}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatItem;
