import { Form, InputGroup } from "react-bootstrap";

const IconInput = ({ name, onChange, type, value, placeholder, icon }) => {
  return (
    <InputGroup className="mb-3">
      <InputGroup.Text id="basic-addon1">{icon}</InputGroup.Text>
      <Form.Control
        name={name}
        onChange={onChange}
        type={type}
        value={value}
        placeholder={placeholder}
      />
    </InputGroup>
  );
};

export default IconInput;
