import { Link } from "react-router-dom";

import { ChevronRightIcon } from "../../../constants/icon_path";

const SettingMenuItem = ({ to, MenuIcon, title, onClick }) => {
  return (
    <div onClick={onClick}>
      <Link to={to}>
        <div className="settings-menu d-flex justify-content-between">
          <div className="d-flex">
            <MenuIcon size={25} className="profile-icon" color="grey" />
            <p className="link-title">{title}</p>
          </div>
          <ChevronRightIcon color="grey" className="arrow-icon" size={15} />
        </div>
      </Link>
    </div>
  );
};

export default SettingMenuItem;
