import { IoChevronBackSharp } from "react-icons/io5";
import { Link } from "react-router-dom";

import { ROUTE } from "../../../constants/app_routes";
import UserProfile from "../../../components/shared/UserProfile";

const RoomHeader = ({ title }) => {
  return (
    <div className="room_header">
      <div className="background-layer">
        <div className="content">
          <Link to={ROUTE.HOME}>
            <IoChevronBackSharp size={40} color="white" />
          </Link>
          <h2 className="text-white">{title}</h2>
        </div>
        <UserProfile />
      </div>
    </div>
  );
};

export default RoomHeader;
