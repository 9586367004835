import { useEffect, useRef } from "react";

import ChatItem from "./ChatItem";

const ChatListContainer = ({ data }) => {
  const messageRef = useRef(null);

  useEffect(() => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 50) {
      messageRef.current?.scrollIntoView({ behavior: "smooth" });
      window.scrollTo(1000, 1000);
    }
  }, [data]);

  return (
    <div className="chat_container">
      {data?.map((message) => {
        return (
          <div key={message.id} ref={messageRef}>
            <ChatItem message={message} />
          </div>
        );
      })}
    </div>
  );
};

export default ChatListContainer;
