import { Button, Spinner } from "react-bootstrap";

const SubmitButton = ({
  text,
  variant = "success",
  size = "md",
  isBlockButton = true,
  loading,
  disabled,
}) => {
  return (
    <div className={`${isBlockButton ? "d-grid gap-2" : ""}`}>
      <Button
        type="submit"
        variant={variant}
        size={size}
        disabled={disabled || loading}
      >
        {loading ? (
          <Spinner animation="border" variant="light" size="sm" />
        ) : null}{" "}
        {text}
      </Button>
    </div>
  );
};

export default SubmitButton;
