/* eslint-disable react/prop-types */
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { simpleDate } from "../../../utils/formatDate";

const DepositTable = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Table striped bordered responsive variant="dark">
      <thead>
        <tr>
          <th className="text-center">#</th>
          <th className="text-center">{t("depositDate")}</th>
          <th className="text-center">{t("amount")}</th>
          <th className="text-center">{t("profit")}</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((d, i) => (
          <tr key={d.id}>
            <td className="text-center">{i + 1}</td>
            <td className="text-center">{simpleDate(d.createdAt)}</td>
            <td className="text-center">{d.amount}</td>
            <td className="text-center">{d.profit * 100}%</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default DepositTable;
