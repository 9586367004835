import { Form } from "react-bootstrap";

const CustomRadio = ({ items, handleChange, name, disabled, defaultValue }) => {
  defaultValue;
  return (
    <div className="custom-radio">
      {items?.map((item, i) => (
        <Form.Check
          key={item.name}
          inline
          label={item.label}
          name={name}
          type="radio"
          defaultChecked={i == 0}
          checked={item.value == defaultValue}
          onChange={handleChange}
          value={item.value}
          disabled={disabled}
          defaultValue={defaultValue}
        />
      ))}
    </div>
  );
};

export default CustomRadio;
