export const ERROR_MESSAGE = {
  WRONG_CREDENTIAL: "WrongCredential",
  REQUIRED_PASSWORD: "ReruiredPassword",
  REQUIRED_PASSWORD_CONFIRM: "ReruiredPasswordConfirm",
  PASSWORD_NOT_MATCH: "PasswordNotMatch",
  MEMBER_NOT_FOUND: "MemberNotFound",
  REQUIRED_BANK_NAME: "RequiredBankName",
  REQUIRED_ACCOUNT_NAME: "RequiredAccountName",
  REQUIRED_ACCOUNT_NO: "RequiredAccountNo",
  REQUIRED_AMOUNT: "RequiredAmount",
  REQUIRED_BANKNAME_ENG: "RequiredBankNameEng",
  REQUIRED_NAME_ENG: "RequiredNameEng",
  DOC_EXIST: "DocExist",
  TRANSACTION_NOT_AVAILABLE: "TransactionNotAvailable",
  TRANSACTION_NOT_FOUND: "TransactionNotFound",
  INSUFFICIENT: "Insufficient",
  ROOM_NOT_FOUND: "RoomNotFound",
  USER_NOT_SET_WITHDRAW_PASSWORD: "UserNotSetWithdrawPassword",
  MAX: "MaxBet",
  USER_BLOCKED: "UserBlocked",
  REQUIRED_CRYPTO_ADDRESS: "REQUIRED_CRYPTO_ADDRESS",
  REQUIRED_CRYPTO_NETWORK: "REQUIRED_CRYPTO_NETWORK",
  REQUIRED_NAME: "REQUIRED_NAME",
  REQUIRED_LASTNAME: "REQUIRED_LASTNAME",
  REQUIRED_PROFIT: "REQUIRED_PROFIT",
  REQUIRED_ROOM: "REQUIRED_ROOM",
  WRONG_PASSWORD: "WRONG_PASSWORD",
  USER_NOT_FOUND: "USER_NOT_FOUND",
  DATA_NOT_FOUND: "DATA_NOT_FOUND",
  TRANSACTION_UNAVAILABLE: "TRANSACTION_UNAVAILABLE",
  REQUIRED_USERNAME: "REQUIRED_USERNAME",
  OLD_PASSWORD_INCORRECT: "OLD_PASSWORD_INCORRECT",
  USERNAME_EXIST: "USERNAME_EXIST",
  REQUIRED_NICKNAME: "REQUIRED_NICKNAME",
  REQUIRED_OPTIONS: "REQUIRED_OPTIONS",
  REQUIRED_LOTTERY: "REQUIRED_LOTTERY",
  REQUIRED_COUNTRY: "REQUIRED_COUNTRY",
  REQUIRED_WITHDRAW_AMOUNT: "REQUIRED_WITHDRAW_AMOUNT",
  REQUIRED_TOPUP_AMOUNT: "REQUIRED_TOPUP_AMOUNT",
  ACCOUNT_NOT_ACONNECTED: "ACCOUNT_NOT_ACONNECTED",
  REQUIRED_MINIMUM_BALANCE: "REQUIRED_MINIMUM_BALANCE",
  REQUIRED_OLD_PASSWORD: "ReruiredOldPassword",
  REQUIRED_NEW_PASSWORD: "ReruiredNewPassword",
  REQUIRED_CARD_NAME: "REQUIRED_CARD_NAME",
  REQUIRED_CARD_NO: "REQUIRED_CARD_NO",
  REQUIRED_CARD_FILE: "REQUIRED_CARD_FILE",
  WITHDRAW_PASSWORD_INCORRECT: "WITHDRAW_PASSWORD_INCORRECT",
};
