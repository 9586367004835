import { Card, Col, Placeholder, Row } from "react-bootstrap";

const CountryItem = () => {
  return (
    <Row className="p-2">
      <Col xs="2" md="2">
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder
            style={{
              height: "5rem",
              width: "5rem",
              borderRadius: "7rem",
            }}
            xs={10}
            size="lg"
          />
        </Placeholder>
      </Col>
      <Col xs="4" md="10">
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder
            style={{ height: "2rem", marginTop: "1.5rem" }}
            xs={10}
            size="lg"
          />
        </Placeholder>
      </Col>
      <Col xs="2" md="2">
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder
            style={{
              height: "5rem",
              width: "5rem",
              borderRadius: "7rem",
            }}
            xs={10}
            size="lg"
          />
        </Placeholder>
      </Col>
      <Col xs="4" md="10">
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder
            style={{ height: "2rem", marginTop: "1.5rem" }}
            xs={10}
            size="lg"
          />
        </Placeholder>
      </Col>
    </Row>
  );
};

export default CountryItem;
