import { Container } from "react-bootstrap";
import { BiHomeAlt } from "react-icons/bi";
import { GiWallet } from "react-icons/gi";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { FcCustomerSupport } from "react-icons/fc";
import { CgProfile } from "react-icons/cg";
import { Link } from "react-router-dom";
import { ROUTE } from "../../../constants/app_routes";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Container className="mt-1" fluid>
      <div className="footer">
        <div className="background-layer">
          <div className="item">
            <Link to={ROUTE.HOME}>
              <BiHomeAlt className="footer-con" size={20} />
              <p>{t("home")}</p>
            </Link>
          </div>
          <div className="item">
            <Link to={ROUTE.TOPUP}>
              <GiWallet className="footer-con" size={20} />
              <p>{t("recharge")}</p>
            </Link>
          </div>
          <div className="item">
            <Link to={ROUTE.WITHDRAW}>
              <MdOutlineAccountBalanceWallet className="footer-con" size={20} />
              <p>{t("withdraw")}</p>
            </Link>
          </div>
          <div className="item">
            <Link to={ROUTE.SUPPORT}>
              <FcCustomerSupport className="footer-con" size={20} />
              <p>{t("customerService")}</p>
            </Link>
          </div>
          <div className="item">
            <Link to={ROUTE.PROFILE}>
              <CgProfile className="footer-con" size={20} />
              <p>{t("profile")}</p>
            </Link>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
