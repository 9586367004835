import { Col, FormGroup, Input, Label } from "reactstrap";

const RowFormGroup = ({
  name,
  onChange,
  type,
  label,
  labelCol = 3,
  inputCol = 9,
  placeholder,
  disabled,
  options,
}) => {
  if (type === "select") {
    return (
      <FormGroup row>
        <Label sm={labelCol}>{label}</Label>
        <Col sm={inputCol}>
          <Input
            name={name}
            placeholder={placeholder}
            type={type}
            onChange={onChange}
            disabled={disabled}
          >
            {options?.map((option) => {
              return <option key={option}>{option}</option>;
            })}
          </Input>
        </Col>
      </FormGroup>
    );
  } else {
    return (
      <FormGroup row>
        <Label sm={labelCol}>{label}</Label>
        <Col sm={inputCol}>
          <Input
            name={name}
            placeholder={placeholder}
            type={type}
            onChange={onChange}
            disabled={disabled}
          />
        </Col>
      </FormGroup>
    );
  }
};

export default RowFormGroup;
