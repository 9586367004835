import { gql } from "@apollo/client";
import { MEMBER_INFO } from "../fragments";

export const LOGIN = gql`
  mutation loginMember($username: String!, $password: String!) {
    loginMember(username: $username, password: $password) {
      user {
        username
      }
      tokens {
        accessToken
        refreshToken
      }
    }
  }
`;
export const REGISTER = gql`
  mutation registerMember($input: MemberInput!) {
    registerMember(input: $input) {
      ...memberInfo
    }
  }
  ${MEMBER_INFO}
`;
export const LOGOUT = gql`
  mutation logout {
    logout
  }
`;
