import { useState } from "react";
import { Carousel, Container } from "react-bootstrap";

import { homeBg, homeBg2, homeBg3 } from "../../../constants/image_path";

const HomeCarousel = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => setIndex(selectedIndex);

  return (
    <Container className="mt-2 mb-2">
      <Carousel activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item>
          <img
            className="img-thumbnail home__bg"
            src={homeBg}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="img-thumbnail home__bg"
            src={homeBg2}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="img-thumbnail home__bg"
            src={homeBg3}
            alt="Second slide"
          />
        </Carousel.Item>
      </Carousel>
    </Container>
  );
};

export default HomeCarousel;
