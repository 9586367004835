import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { BGS } from "../../../constants/app_constants";

const RoomCard = ({ room, handleEnterRoom, i }) => {
  const { t } = useTranslation();
  return (
    <div
      key={room.id}
      className="room-card"
      onClick={() => handleEnterRoom(room)}
    >
      <Card>
        <img src={BGS[i]} alt="room" className="room-img" />
        <div className="card-text">
          <h1>{room.name}</h1>
          <h3>{t("minimumPurchase")}</h3>
          <h3>{t("enterMinimum")}</h3>
        </div>
      </Card>
    </div>
  );
};

export default RoomCard;
