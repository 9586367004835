import { Suspense, useEffect } from "react";

import "./App.css";
import "./assets/scss/main.scss";
import AppRoutes from "./routes/appRoute";
import Preloader from "./components/common/Preloader";

function App() {
  useEffect(() => {
    const language = localStorage.getItem("language");
    if (!language) {
      localStorage.setItem("language", "th");
    }
  }, []);

  return (
    <Suspense fallback={<Preloader />}>
      <AppRoutes />
    </Suspense>
  );
}

export default App;
