import BaseLayout from "../../components/layout";
import Footer from "../../components/layout/footers/Footer";
import Header from "../../components/layout/headers/HeaderHome";
import CountryList from "./components/CountryList";
import HomeCarousel from "./components/HomeCarousel";
import HomeMenu from "./components/HomeMenu";
import MarketTrending from "./components/MarketTrending";

const Home = () => {
  return (
    <BaseLayout>
      <div className="home">
        <Header />
        <HomeCarousel />
        <HomeMenu />
        <CountryList />
        <MarketTrending />
        <Footer />
      </div>
    </BaseLayout>
  );
};

export default Home;
